<template>
    <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-col
                v-for="(input, index) in inputs"
                :key="index"
                class="ion-justify-content-center"
            >
                <ion-input
                    v-model="inputs[index].value"
                    :name="`verifyCode${index + 1}`"
                    type="tel"
                    inputmode="numeric"
                    :tabindex="index + 1"
                    :maxlength="index == 0 ? '6' : '1'"
                    label-placement="stacked"
                    fill="solid"
                    shape="round"
                    class="native-input sc-ion-input-md ion-text-center dink-input"
                    :class="inputs[index].classes"
                    :autocomplete="index == 0 ? 'one-time-code' : null"
                    @input="onInput"
                    @keyup="focusNextInput"
                    @keyup.enter="onVerify"
                    @paste="onPaste"
                />
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <span class="resend-button" :onClick="handleResendCode">Resend code</span>
            </ion-col>
        </ion-row>
        <ion-row v-if="errorMessage">
            <ion-col>
                <div
                class="error ion-margin-top"
              >{{ errorMessage }}</div>
            </ion-col>
        </ion-row>
        <ion-toast 
            :is-open="toastOpen" 
            :message="successMessage" 
            :duration="2000"
            position="bottom"
            :icon="checkmarkCircleOutline"
            position-anchor="footer"
            class="success-toast"
            @didDismiss="setToast(false)"
        ></ion-toast>
  </ion-grid>
</template>

<script>
import { IonCol, IonGrid, IonRow, IonInput, IonToast } from '@ionic/vue';
import { checkmarkCircleOutline } from 'ionicons/icons';
import FormVerification from '@/composables/verification.js'
import { ref, defineExpose } from 'vue';
import { mapActions } from 'vuex';

const { displayErrorMessage, validateForm } = FormVerification();

export default {
    name: 'VeriyfForm',
    expose: ['onVerify'],
    components: {
        IonCol, IonGrid, IonRow, IonInput, IonToast
    },

    props: {
        count: {
            type: Number,
            default: 6,
            required: true
        },
        phoneNumber: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        initial: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            inputs: [],
            otp: null,
            errorMessage: null,
            successMessage: null,
            toastOpen: false,
            checkmarkCircleOutline
        };
    },

    created() {
        // Create input dynamically based on count
        var i = 0;
        var initial = this.initial || '';
        this.inputs = Array(this.count).fill().map(() => ({
            value: initial[i++] || '',
            rules: [
                (v) => !!v || 'Complete verification code is required',
            ], 
            error: {
                messages: []
            },
            classes: {
                'error': false
            }
        }));
    },

    mounted() {
        this.initOtp(); // Call the method on component mount
    },

    emits:["update:modelValue", "redirectCallback"],
    methods: {
        ...mapActions({verifyCode:'auth/verifyCode'}),
        ...mapActions({resendCode:'auth/login'}),
        onInput(e) {
            this.numberOnlyValidation(e);
            this.$emit("update:modelValue", e.target.value);

            if(e.target.value.length == 6){
                this.onVerify();
            }
        },

        onAutoFill(e) {
            if(e.target.value.length == 6){
                this.onVerify();
            }
        },

        numberOnlyValidation(event) {
            const pattern = /[0-9.,]/;
            let inputChar = String.fromCharCode(event.charCode);

            if (!pattern.test(inputChar)) {
                // invalid character, prevent input
                event.preventDefault();
            }
        },

        resetCode(){
            for (var i = 0; i < this.inputs.length; i++) {
                this.inputs[i].value = "";
            }
        },

        getCode(){
            var code = "";
            for (var i = 0; i < this.inputs.length; i++) {
                code = code + this.inputs[i].value;
            }

            return code;
        },

        focusNextInput(event) {
            const currentTabIndex = event.target.tabIndex;
            if(event.key === "Backspace" || event.key === "Delete") {
                const prevElement = document.querySelector(`[tabIndex="${currentTabIndex - 1}"]`);
                if (prevElement) {
                    prevElement.focus();
                }
            } else if (!isNaN(event.target.value) && event.target.value.length > 0) {        
                const nextElement = document.querySelector(`[tabIndex="${currentTabIndex + 1}"]`);
                if (nextElement) {
                    nextElement.focus();
                }
            }
        },

        async initOtp() {
            if ('OTPCredential' in window) {
                navigator.credentials.get({
                    otp: { transport:['sms'] }
                }).then(otp => {
                    this.pasteInput(otp.code);
                });
            } 
        },

        handleOtpChange(event) {
            this.pasteInput(event.target.value);
        },

        onPaste(event) {
            event.preventDefault();
            const pastedText = (event.clipboardData || window.clipboardData)?.getData('text');
            const currentElement = document.querySelector(`[tabIndex="${pastedText.length}"]`);

            this.pasteInput(pastedText);

            currentElement.focus();
        },

        pasteInput(pastedText){
            if (pastedText) {
                // Loop though paste code and add it to inputs
                for (var i = 0; i < pastedText.length; i++) {
                    if(!isNaN(pastedText[i])){
                        this.inputs[i].value = pastedText[i];          
                    }
                }   
            }
        },

        async handleResendCode(){
            var options = null;
            if(this.phoneNumber != null){
                options = {
                    mobile: this.phoneNumber 
                }
            } else {
                options = {
                    email: this.email        
                }
            }

            await this.resendCode(options).then(r => {
                if(r.status && (200 <= r.status && r.status < 300)) {
                    this.successMessage = `Code sent`;
                    this.setToast(true);             
                } else {
                    this.errorMessage = `Error resending code please try again`;
                }
            }).catch((err) => {
                this.errorMessage = `Error resending code please try again`;
            });
        },

        async onVerify() {
            /*if(!validateForm(this.inputs)){
                this.errorMessage = displayErrorMessage(this.inputs);
                return false;
            }*/
            
            var options = {
                code: this.getCode()     
            }

            if(options != null){
                await this.verifyCode(options).then(r => {
                    if(r.status && (200 <= r.status && r.status < 300)) {
                        // Remove the on paste event from the ion-inputs so it does not leak to other pages.
                        document.removeEventListener('paste', this.onPaste);
                        this.$router.push('/matches/schedule');
                    } else {
                        this.errorMessage = `Error verifying code please try again`;
                    }
                }).catch((err) => {
                    this.resetCode();
                    if(err.response?.data?.attempt && err.response?.data?.of){
                        if(parseInt(err.response.data.attempt) == parseInt(err.response.data.of)){
                            // redirectCallback calls backButtonCallbackHandler function to redirect user back to login panel
                            this.$emit('redirectCallback', {'page':'/login'});
                        }
                        this.errorMessage = `Invalid Code ${ (err.response.data.of - err.response.data.attempt) } of ${ err.response.data.of } attempts left. `;
                    } else {
                        this.errorMessage = `Error verifying code please try again`;
                    }
                });
            }
        },
        setToast(state) {
            this.toastOpen = state;
        }
    },
}
</script>

<style scoped>
ion-input.dink-input {
    --border-radius: 12px;
    font-weight: bold;
    font-size: 1.1em;
    min-width: 37px;
    max-width: 70px;
}

ion-toast {
    --background: #333333;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --height: 80px;
  }

.success-toast {
    color: #ffffff;
}

.resend-button {
    margin-left: 5px;
    font-weight: 500;
    font-size: 0.8em;
}
</style>