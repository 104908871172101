<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <AvatarToolbar></AvatarToolbar>
            <MatchChip active="history"></MatchChip>
        </ion-header>            
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row>
                    <ion-grid>
                        <ion-row>
                            <ion-col>
                                <img src="/img/banners/banner-history.png"  class="banner-image">
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-row>
            </ion-grid>      
        </ion-content>
    </ion-page>    
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip } from '@ionic/vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import MatchChip from '@/components/ui/nav/MatchChip.vue';

export default {
    name: 'History',
    components: {
        IonPage, IonNav, AvatarToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, 
        IonHeader, IonChip, MatchChip
    }
}
</script>