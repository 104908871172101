import api from '@/services/api';

const profiles = {
    namespaced: true,

    state: {
        data: [],
    },

    getters: {
        data(state) {
            return state.data;
        }
    },

    actions: {
        async patch({ commit, rootState }, params){
            const user = rootState.auth.user;
            
            if (!user) {
                return;
            }

            const r = await api.patch(`/social/profiles/${user.id}/`, params)

            commit('UPDATE_PROFILE', r.data);

            return r;
        },
    },

    mutations: {
        ADD_PROFILE(state, data) {  
            state.data.push(data);
        },

        UPDATE_PROFILE(state, data) {
            state.data[state.data.findIndex(obj => obj.id === data.id)] = data;
        },
    },
}

export default profiles;