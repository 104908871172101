<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <AvatarToolbar></AvatarToolbar>
            <CommunityChip
                active="faq"
            ></CommunityChip>
        </ion-header>            
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-accordion-group expand="inset">
                            <ion-accordion value="memebership">
                                <ion-item slot="header" color="light">
                                    <ion-label>When do you open?</ion-label>
                                </ion-item>
                            <div class="ion-padding" slot="content">While our Fishers facility is set to open in the fall of 2024, our app will be available for download in the upcoming months.</div>
                            </ion-accordion>
                            <ion-accordion value="payments">
                                <ion-item slot="header" color="light">
                                    <ion-label>Where are you located?</ion-label>
                                </ion-item>
                            <div class="ion-padding" slot="content">You'll find us situated at the former Marsh location in Fishers, conveniently located at the intersection of 116th Street and Brookschool Road.</div>
                            </ion-accordion>
                            <ion-accordion value="hours">
                                <ion-item slot="header" color="light">
                                    <ion-label>How old do you have to be to play?</ion-label>
                                </ion-item>
                            <div class="ion-padding" slot="content">Players must be at least 18 years old to participate unless accompanied by a parent or legal guardian.</div>
                            </ion-accordion>
                            <ion-accordion value="cancel">
                                <ion-item slot="header" color="light">
                                    <ion-label>How will I be charged to play?</ion-label>
                                </ion-item>
                            <div class="ion-padding" slot="content">To schedule any match, each player must have a credit card on file. You have the option to either pay for all players or allow each player to pay for their own court time using their individual credit card.</div>
                            </ion-accordion>
                        </ion-accordion-group>        
                    </ion-col>
                </ion-row>                                                        
            </ion-grid>
        </ion-content>  
    </ion-page>    
</template>

<script>
import { IonPage, IonNav, IonContent, IonIcon, IonItem, IonLabel, IonHeader, IonFooter, IonChip, IonGrid, IonCol, IonRow, 
    IonAccordion, IonAccordionGroup } from '@ionic/vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import CommunityChip from '@/components/ui/nav/CommunityChip.vue';

export default {
   name: 'FAQ',
   components: {
       IonPage, IonNav, AvatarToolbar, IonContent, IonIcon, IonItem, IonLabel, IonHeader, IonFooter, IonChip, IonGrid, 
       IonCol, IonRow, IonAccordion, IonAccordionGroup, CommunityChip
   }
}
</script>

<style scoped>
ion-accordion-group {
    --background: #F7F7F7;
    --border-radius: 100px;
    width: 97%;
    margin-left: 3px;
    margin-right: 3px;
}

ion-label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
</style>