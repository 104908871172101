import api from '@/services/api';

const payment = {
    namespaced: true,

    state() {
        return {
            packages: null,
        };
    },

    actions: {
        async getPackages(context, payload = { id: null, coupon: null }) {
            try {
                const { id, coupon } = payload;

                // if id is in the payload, /id is added, if coupon is in payload ?coupon_code=coupon is added
                const url = `/payment/packages/` +
                (id !== undefined ? id + '/' : '') +
                (coupon !== undefined ? '?coupon_code=' + coupon : '');

                const r = await api.get(url);
                this.state.packages = r.data;

                return this.state.packages;
            } catch (err) {
                if (err.response?.status) {
                    throw new Error(err.response?.status);
                }
                throw err;
            }
        }
    }
}

export default payment;