<template>
    <div class="ion-margin-top chip-nav">
        <ion-chip :class="{ active: active === 'settings' }" :onClick="handleSettingClick">Settings</ion-chip>
        <ion-chip :class="{ active: active === 'faq' }" :onClick="handleFaqClick">FAQ</ion-chip>
        <ion-chip :class="{ active: active === 'support' }" :onClick="handleSupportClick">Support</ion-chip>
    </div>
</template>

<script>
import { IonChip } from '@ionic/vue';

export default {
  name: 'NavCommunityChip',

  components: {
    IonChip
  },

  props: {
    active: {
      type: String,
      default: null,
    }
  },

  methods: {
    async handleSettingClick(){
        this.$router.push('/community/settings');
    },
    async handleFaqClick(){
        this.$router.push('/community/faq');
    },
    async handleSupportClick(){
        this.$router.push('/community/support');
    }
  },

}
</script>

<style scoped>
    ion-chip {
        --background: transparent;
        min-width: 110px;
        display: flex;
        justify-content: center;
        min-width: 31%;
    }

    ion-chip.active {
        --background: #044784;
        --color: #fff;
        margin: 0px;
        min-width: 32%;
    }
</style>