<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
  <Loading></Loading>
</template>

<script>
  import { IonApp, IonRouterOutlet, loadingController } from '@ionic/vue';
  import Loading from '@/components/Loading.vue';

  export default {
    components: { IonApp, IonRouterOutlet, Loading },
  };
</script>