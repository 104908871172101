<template>
    <ion-page>
        <Header
            logo="white"
            type="logo"
        ></Header>
        <ion-content class="ion-padding"
          v-if="showContent"
        >
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-row class="ion-align-items-center">
                    <img src="/img/screenshots/hero-phone.png" 
                    width="375" 
                    height="641" 
                    alt="Install 24/7 Dink"
                    class="hero-image">
                  </ion-row>
                </ion-col>
              </ion-row>
              <ion-row v-if="os === 'android'">
                <ion-col class="ion-margin-top ion-justify-content-center">
                  <ion-button
                    v-if="showDownload"
                    type="submit" 
                    shape="round" 
                    expand="block" 
                    class="ion-margin-bottom"
                    :onClick="handleDownloadPWA">Download PWA</ion-button>
                  <ion-button
                    v-if="showOpenPWA" 
                    type="submit" 
                    shape="round" 
                    expand="block" 
                    class="ion-margin-bottom"
                    :onClick="handleDownloadPWA">Open PWA</ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
            <IphoneDownloadModal 
            :showIphoneModal="showIphoneModal"></IphoneDownloadModal>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonNav, IonPage, IonContent, IonCol, IonGrid, IonRow, IonButton, IonButtons, IonModal, 
  IonHeader, IonToolbar, IonTitle, IonItem, isPlatform, getPlatforms  } from '@ionic/vue';
import { mapActions } from 'vuex';
import Header from '@/components/Header.vue';
import IphoneDownloadModal from '@/components/modals/IphoneDownloadModal.vue';

export default {
    name: 'Package Selection',
    components: {
        IonNav, IonPage, IonContent, IonCol, IonGrid, IonRow, IonButton, IonButtons, IonModal,
        IonHeader, IonToolbar, IonTitle, IonItem, isPlatform, getPlatforms, Header, IphoneDownloadModal
    },

    data() {
      return {
        user: null,
        os: null,
        platforms: null,
        isStandalone: false,
        isPWAInstalled: false,
        isIos: false,
        isAndroid: false,
        isSafari: false,
        deferredPrompt: null,
        showDownload: true,
        showOpenPWA: false,
        installedApps: false,
        relatedApps: false,
        media: false,
        navigator: false,
        andref: false,
        isInstalled: "",
        action: "Not sure",
        showIphoneToast: false,
        showContent: false,
        showIphoneModal: false
      };
    },

    async mounted() {
      // If a user is not logged in a 403 is returned and must be caught
      this.user = await this.whoami().catch((err) => {});  
      
      if(this.user) {
        this.goToSchedule();
      } else {
        this.goToLogin();
      }

      /*
      window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
      const userAgent = window.navigator.userAgent.toLowerCase();
      var userOS = "";
      
      
      // Check if PWA is running if not show PWA home page if not then continue
      this.isPWAInstalled = this.isPWA();
      if(this.isPWAInstalled){
        // show PWA homepage
        this.action = "Show or redirect to PWA login, subscribe, or invite a friend"
        
        // Check if user is logged in if not go to login
        if(this.user) {
          // Check if user is subscribed if not go to subscribe
          if(this.user.is_paying_member){
            // User has subscribed
            this.goToInvite();
            return true;
          } else {
            // User has not subscribed
            this.goToSubscribe();
            return true;
          }     
        } else {
          // User not logged in
          this.goToLogin();
          return true;
        }
      }

      // If not a PWA then display homepage content
      this.showContent = true;

      // Check what phone type
      userOS = this.checkOS(userAgent);
      this.os = userOS;
      
      // Check if PWA is installed based on different phone ( each has its own logic)
      if(userOS == "android" && this.isPWAInstalled == false){
        this.showDownload = true;
        this.showOpenPWA= false;
        this.action = "show install message pop up android install"
        console.log("handleDownloadPWA");
        this.handleDownloadPWA();
      } else if((userOS == "ios" || userOS == "iphone") && this.isPWAInstalled == false){
        
        // Show install message
        this.action = "show install modal"
        this.showDownload = false;
        this.showOpenPWA = false;
        this.showIphoneModal = true;
      } else if(userOS == "safarii"){
          
        //Safari offers a property called navigator.standalone which indicates if the current webpage is saved as a home screen icon.
          // check navigator if not installed then 
          this.action = "show install modal"
          this.showIphoneModal = true;
          this.showDownload = false;
          this.showOpenPWA= false;
      }

      // Below this point is testing code

      console.log("isPWAInstalled ", this.isPWAInstalled);

      // Begin check to decide if PWA is installed Android and Iphone have specfic work around
      
      this.isIos = /iphone|ipad|ipod/.test(userAgent);
      this.isAndroid = /Android/.test(userAgent);
      this.isSafari = /Safari/.test(userAgent);

      this.checkStandaloneMode();
      
      this.checkRelatedApps();
      this.platforms = getPlatforms();

      this.media = window.matchMedia('(display-mode: standalone)').matches;
      this.navigator = navigator.standalone;
      this.andref = document.referrer.includes('android-app://');

      this.isInstalled = !!(this.isStandalone || (this.os && !this.isSafari));

      if (!this.deferredPrompt) {
        console.log("deferredPrompt test");

        //this.showDownload = false;
        //this.showOpenPWA = true;
        return; // Prompt not available user already installed
      }*/
    },

    beforeDestroy() {
      window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    },

    methods: {
      ...mapActions({
        whoami: 'auth/whoami',
      }),

      goToHome() {
        this.$router.push('/home');
      },

      goToLogin() {
        this.$router.push('/login');
      },

      goToInvite() {
        this.$router.push('/invite/friend');
      },

      goToSubscribe(){
        this.$router.push('/subscribe');
      },

      goToSchedule(){
        this.$router.push('/matches/schedule');
      },

      toggleIphoneModal(){
        this.showIphoneModal = !this.showIphoneModal
      },

      handleBeforeInstallPrompt(e) {
        // Prevents the default install dialog from appearing on mobile
        e.preventDefault();
        this.deferredPrompt = e;
      },

      async handleDownloadPWA(){
        if (!this.deferredPrompt) {
          return; // Prompt not available user already installed
        }

        const { outcome } = await this.deferredPrompt.prompt();
        this.deferredPrompt = null;
      },
    
      getPWADisplayMode() {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
          return 'twa';
        } else if (navigator.standalone || isStandalone) {
          return 'standalone';
        }
        return 'browser';
      },

      checkStandaloneMode() {
        if (isPlatform('hybrid') && window.matchMedia('(display-mode: standalone)').matches) {
            this.isStandalone = true;
            // Perform actions based on standalone mode
        }
      },

      checkOS(userAgent){
        if(isPlatform('ios')){
          return "ios";
        } else if(isPlatform('android')){
          return "android";
        } else if(isPlatform('cordova')){
          return "cordova";
        } else if(isPlatform('mobileweb')){
          return "mobileweb";
        } else if(/Safari/.test(userAgent)){
          return "safarii";
        } 

        return false;
      },

      isPWA() {
        if (isPlatform('pwa')) {          
            return true
        }
        
        return false;
      },

      async checkRelatedApps() {
        try {
            const installedApps = await navigator.getInstalledRelatedApps();

            const relatedApps = await navigator.getInstalledRelatedApps();
                relatedApps.forEach((app) => {
            });
            
            this.installedApps = installedApps;
            this.relatedApps = relatedApps;
        } catch (error) {
            console.error('Error checking related apps:', error);
        }
      }
    }
  }
</script>

<style scoped>
.hero-image {
  max-width: 100%;
  height: auto;
}

ion-button {
    height: 50px;
}
</style>