<template>
    <DefaultInput
        name="email"
        label="email"
        placeholder="Enter your email"
        v-model="value"
        class="ion-margin-bottom"
        :class="this.formData.email.classes"
        :value="formatEmail"
        @ionBlur="handleEmailChange"
        @keyup="handleKeyUp"
    ></DefaultInput>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { mapActions } from 'vuex';
import DefaultInput from '@/components/forms/DefaultInput.vue';
import FormVerification from '@/composables/verification.js'
import * as emailValidator from 'email-validator';

const { isValidEmail } = FormVerification();

export default {
  name: 'EmailInput',
  expose: ['validEmail'],
  components: {
        DefaultInput
  },

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    unique: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    formatEmail() {
      var clean = ( this.modelValue || '');

      return clean;
    }
  },

  data() {
    return {
      value: this.modelValue,
      rules: [
        (v) => !!v || 'Required',
      ],
      classes: {
        'error': false
      },
      formData: {
        email: {
          value: this.formatEmail,
          rules: [
            (v) => !!v || 'Email is a required field',
            (v) => emailValidator.validate(v) || 'Email must be valid'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        }
      },
      typingCheck:false,
      errorMessage: ''
    }
  },

  watch: {
    value(value) {
      this.$emit('update:modelValue', value);
    },
  },

  methods: {
    ...mapActions({checkEmail:'auth/checkUnique'}),
    handleKeyUp() {
      // Typing check added if onblur check fails
      if(this.typingCheck){
        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
            this.validEmail(this.value);
        }, 500);
      }
    },

    async validEmail(emailToCheck = false){
      var r = null;
      var errorMessage = "";
      this.formData.email.classes.error = false;
      this.errorMessage = '';

      if(!emailToCheck || emailToCheck.length <= 0){
        this.formData.email.classes.error = true;
        return false;
      }

      if(!emailValidator.validate(emailToCheck)){
        this.formData.email.classes.error = true;
        this.errorMessage = "Email is not valid.";
        this.$emit('update-error-message', this.errorMessage);
        return false;
      }

      r = await this.checkEmail({ 
        email: emailToCheck 
      }).catch((r) => {
          return r.response;
      });

      if(r.status && r.status != 404) {
        if(r.status == 200) {
          // email found
          this.errorMessage = "Email is already being used.";       
        } else if (r.status == 400) {
          // banned email list
          this.errorMessage = "This email is not allowed.";
        } else {
          this.errorMessage = "There was an issue please try again.";
        }

        this.$emit('update-error-message', this.errorMessage);
        this.formData.email.classes.error = true;
        return false;
      }  else {

        // email not found and is valid ( 404 )
        this.formData.email.classes.error = false;
        this.$emit('update-error-message', '');
        return true;
      }
    },

    async handleEmailChange(event){
      // If blur check fails add typing check
      if(!await this.validEmail(event.target.value)) {
        this.typingCheck = true;
      }
    }
  }
}
</script>