<template>
    <ion-grid>
        <ion-row>
            <ion-col>
                <label class="ion-margin-horizontal small">{{label}}</label>
            </ion-col>
        </ion-row>  
        <ion-row>
            <ion-col>
                <ion-input
                    :name="name"
                    label-placement="stacked" 
                    fill="solid"
                    :placeholder="placeholder"
                    v-model="modelValue"
                    @input="onInput"
                    @ionFocus="onFocus"
                    @ionBlur="onBlur"
                    class="native-input ion-text-start dink-input"></ion-input>
                    <ion-button v-if="type === 'button'"
                        type="submit" 
                        shape="round" 
                        expand="block" 
                        class="coupon-button"
                        @click="$emit('click')">Apply</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { IonCol, IonGrid, IonRow, IonInput, IonButton } from '@ionic/vue';

export default {
    name: 'DefaultInput',

    components: {
        IonCol, IonGrid, IonRow, IonInput, IonButton
    },

    props: {
        name: {
            type: String,
            default: '',
            required: true
        },
        label: {
            type: String,
            default: '',
            required: true
        },
        placeholder: {
            type: String,
            default: '',
            required: false
        },
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: '',
            required: false
        },
        onClick: {
            type: Function,
            default: undefined, // Optional only for Button type
        },
    },

    data() {
        return {
            modelValue: this.modelValue
        };
    },

    emits:["update:modelValue","focus", "blur"],
    methods: {
        onInput(e) {
            this.$emit("update:modelValue", e.target.value);
        },
        onFocus(e) {
            this.$emit('focus');
        },
        onBlur(e) {
            this.$emit('blur');
        }
    },
    
    created() {
        this.modelValue = this.value;
    }
}
</script>

<style scoped>
label.small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}
ion-button {
    width: 83px;
}
</style>