<template>
    <ion-grid>
        <ion-row>
            <ion-col>
                <label class="ion-margin-horizontal small">{{label}}</label>
            </ion-col>
        </ion-row>  
        <ion-row>
            <ion-col>
                <ion-datetime-button datetime="datetime" class="ion-item"></ion-datetime-button>
                <ion-modal :keep-contents-mounted="true">
                    <ion-datetime
                        id="datetime"
                        presentation="date"
                        :value="value"
                        :prefer-wheel="wheel"
                        @ionFocus="onFocus"
                        @ionBlur="onBlur"
                        @IonChange="onDateChange"
                    ></ion-datetime>
                </ion-modal>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { IonCol, IonGrid, IonRow, IonInput, IonDatetime, IonDatetimeButton, IonModal } from '@ionic/vue';

export default {
    name: 'DateTimePicker',
    components: {
        IonCol, IonGrid, IonRow, IonInput, IonDatetime, IonDatetimeButton, IonModal
    },

    props: {
        name: {
            type: String,
            default: '',
            required: true
        },
        label: {
            type: String,
            default: '',
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        wheel: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            modelValue: this.modelValue
        };
    },

    emits:["update:modelValue", "focus", "blur"],
    methods: {
        onDateChange(e = false) {
            this.$emit("update:modelValue", e.target.value);
        },
        onFocus(e) {
            this.$emit('focus');
        },
        onBlur(e) {
            this.$emit('blur');
        }
    },
    
    created() {
        this.modelValue = this.value;
    }
}
</script>

<style scoped>
label.small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}

ion-datetime-button {
    min-height: 56px;
    display: flex;
    align-items: stretch;
    border-radius: 12px;
}

ion-datetime-button::part(native) {
    background: #f2f2f2;
}
</style>