import moment from 'moment'

export default function verification() {
    const displayErrorMessage = (form) => {
        var errorMessage = '';

        for (const element in form) {
            if (form[element].error.messages.length > 0) { 
                for (let i = 0; i < form[element].error.messages.length; i++) {
                    errorMessage = errorMessage + form[element].error.messages[i];
                }
            }
        }

        return errorMessage; 
    }
  
    const validateForm = (form) => {
        var valid = false;
        clearErrors(form);

        valid = Object.values(form).every((field) => validateField(field));
        
        return valid;
    }
  
    const validateField = (field) => {
        try {
            return field.rules.every((rule) => {
            const ruleResult = rule(field.value);
            if (ruleResult !== true) {
                field.error.messages.push(ruleResult);
                field.classes.error = true
                return false; 
            }
            return true; 
            });
        } catch (e) {
            return false;
        }
    }
  
    const clearErrors = (form) => {
        for (const element in form) {
            if (form[element].error.messages.length > 0) { 
                form[element].classes.error = false;
                form[element].error.messages = [];
            }
        }
    }

    const isOfAge = (dateString) => {
        try {
            const dob = moment(dateString);
            const today = moment();
            const age = today.diff(dob, 'years');

            return age >= 18;
          } catch (error) {
            return false;
          }
    }
    
    const isValidPhone = (phoneNumber) => {
        const phoneRegex = /^(\d{3}-?\d{3}-?\d{4}|\d{10}|\d{3}-?\d{6})$/;
        const cleanedNumber = phoneNumber.replace(/[^0-9-]/g, '');
  
        // Check if the cleaned number matches the regex and has at least 9 digits
        return phoneRegex.test(cleanedNumber) && cleanedNumber.length >= 9;
    }

    const cleanPhone= (phoneNumber) => {
        // Remove everything but numbers
        let clean = phoneNumber.replaceAll(/\D/g, '');
        
        // Remove leading 1 if one exist
        clean = clean.replaceAll(/^1/g, '');

        // Extract the last 10 numbers
        clean = clean.substring(0, 10);

        return clean;
    }

    const formatPhone= (phoneNumber) => {
        var clean = ( phoneNumber || '');

        // Remove leading + if one exist
        clean = clean.replace(/^[\+]/, '');  
        // Remove leading 1 if one exist
        clean = clean.replaceAll(/^1/g, '');
        // Apply phone ###-###-### formatting
        clean = clean.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

        return clean;
    }

    const formatDiplayPrice= (phoneNumber) => {
        var formatPrice = phoneNumber;
        
        if(formatPrice == 0){
            formatPrice = "Free"
        } else if (formatPrice) {
            formatPrice = "$" + formatPrice/100 + "/year"
        }
        
        return formatPrice;
    }

    return {
        displayErrorMessage,
        validateForm,
        validateField,
        clearErrors,
        isOfAge,
        isValidPhone,
        cleanPhone,
        formatPhone,
        formatDiplayPrice
      }
}