  <template>
    <ion-content class="ion-margin-bottom dink247-login-content"> 
      <form
        @submit.prevent="onLogin"  
      >
        <ion-grid class="dink-login-grid">
          <ion-row class="ion-margin-top ion-align-self-center">
            <ion-col>
              <img src="/img/logos/247dink-pickleball-logo-white.png"
                alt="24/7 Dink"
                width="113"
                height="64"> 
            </ion-col>
          </ion-row>
          <ion-row class="ion-text-center ion-align-items-end ion-flex-grow dink247-welcome-row">
            <ion-col class="ion-align-items-end">
              <h1>One App. <br />Zero Membership Fees. <br/>24/7 Play.</h1>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <DefaultInput
                  name="phone"
                  label="phone number"
                  placeholder="Enter your phone number"
                  v-model="form.login.value"
                  class="dink-login"
                  :class="this.form.login.classes"
                  ref="input"
                  autocomplete="tel"
                  @focus="handleFocus"
                  @blur="handleBlur"
                ></DefaultInput>
            </ion-col>
          </ion-row>
          <ion-row v-if="errorMessage">
            <ion-col>
                <div
                  class="error ion-margin-horizontal"
                >{{ errorMessage }}</div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
    <Footer
      buttonText="Get Started"
      @click="onLogin"
    ></Footer>
  </template>

<script>
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonButton, IonButtons } from '@ionic/vue';
import { mapActions, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import Footer from '@/components/FooterButton.vue';
import DefaultInput from '@/components/forms/DefaultInput.vue';
import FormVerification from '@/composables/verification.js'
import * as emailValidator from 'email-validator';

const { displayErrorMessage, validateForm, isValidPhone, cleanPhone } = FormVerification();

export default {
  name: 'Login',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow, IonButton, IonButtons, Footer, DefaultInput, FormVerification
  },

  data() {
    return {
      form: {
        login: {
          value: null,
          rules: [
            (v) => !!v || 'A phone number is required to login.'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        mobile: {
          value: null,
          rules: [
            
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        email: {
          value: null,
          rules: [
           
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        }
      },
      user: null,
      errorMessage: null,
      inputName: null,
      loginHasFocus: false
    }   
  },
  
  emits:['loginCallback'], 
  methods: {
    ...mapActions({login:'auth/login'}), 
    async onLogin() {
      try {
        var r = null;
        var phone = null;
        var email = null;

        this.errorMessage = '';

        // Rule based form validation
        if(!validateForm(this.form)){
          this.errorMessage = displayErrorMessage(this.form);
          return false;
        } 
        
        // Validate for if the login in a phone or email
        if(emailValidator.validate(this.form.login.value)){
          email = this.form.login.value;

          r = await this.login({
              email: this.form.login.value
          }).catch((r) => {
              if(r.response){
                return r.response;;
              }
          });
        } else if(isValidPhone(this.form.login.value)) {          
          phone = cleanPhone(this.form.login.value);
          phone = this.formatPhone(phone);

          r = await this.login({
              mobile: phone
          }).catch((r) => {
              return r.response;
          });
        } else {
          this.form.login.classes.error = true;
          this.errorMessage = `Please enter a valid email or phone number.`;
          return false;
        }

        if(r){
          if ((200 <= r.status && r.status < 300))  {
            // Any 200 status returned means user exist, set the user based on response
            if (r && r.data) {
                this.user = r.data;
            }
          } else if (r.status==400 && r.data) {
            // Status 400 error occurred return error message if one exist.
            var message = "Please try again.";

            if(r.data.mobile){
              message =  r.data.mobile;
            } else if (r.data.email) {
              message =  r.data.email
            }

            this.form.login.classes.error = true;
            this.errorMessage = `Error logging in: ${message}`;
          } else if (r.status==429) {
            this.form.login.classes.error = true;
            this.errorMessage = `To many attempts have been made to login in, please try again in 1 minute`;
          }

          this.form.email.value = email;
          this.form.mobile.value = phone;
  
          this.$emit('loginCallback', { status: r.status, phone: phone, email: email});
        }
      } catch (err) {
        this.errorMessage = `Error logging in: ${err.message}`;
      }
    },

    handleFocus() {
      this.loginHasFocus = true;
    },

    handleBlur(){
      this.loginHasFocus = false;
    },

    formatPhone(value) {
      let formatted = '';
      formatted = '+1' + value; 

      return formatted;
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1.89em;
  font-weight: 700;
  line-height: 35px;
}

form { 
  display: flex; 
  align-items: stretch; 
  height: 100%;
}

label.phone {
  font-size: 11px;
  font-weight: 500;
}

.light {
  color: #fff;
}

ion-button {
    height: 50px;
}

ion-grid {
  padding-bottom: 3px;
}

.dink-login-grid {
  background: url(/img/backgrounds/dink247-signin-bg.png) no-repeat top center fixed; 
  background-size: 100% auto; 
  background-position-y: calc(-35px + var(--keyboard-offset)* -1);
}

.dink247-login-content {
  --background: none; /* to override ion-content background this must be done or image has white bars */
}

.dink247-welcome-row {
  min-height: 100px;
}
</style>