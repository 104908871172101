<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <FooterTabBar active="community" id="ionTabFooter"></FooterTabBar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import { IonRouterOutlet, IonNav, IonPage, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip } from '@ionic/vue';
import Footer from '@/components/FooterButton.vue';
import MatchChip from '@/components/ui/nav/MatchChip.vue';
import MatchSchedule from '@/components/match/Schedule.vue';
import FooterTabBar from '@/components/ui/FooterTabBar.vue';

export default {
    name: 'Community',
    components: {
        IonRouterOutlet, IonNav, IonPage, Footer, FooterTabBar, IonContent, IonTabs, IonGrid, IonCol, IonRow, 
        IonHeader, IonChip, MatchChip
    }
}
</script>

<style scoped>
ion-button {
    width: auto;
}

.btn-header {
    width: auto;
    --background: $ffffff;
}

.btn-add-schedule {
    --background: #044784;
    --color: #fff;
    height: 32px;
    margin-left: auto;
}

h2 {
    margin-top: 0px;
    line-height: 30px;
}
</style>