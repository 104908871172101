<template>
    <ion-avatar 
      v-if="isAuthenticated"
      @click="goToProfile()"
    >
      <img 
        :alt="alt" 
        :src="avatar"  />
    </ion-avatar>
</template>

<script>
import { mapGetters } from 'vuex';
import { IonAvatar } from '@ionic/vue';
import { API_BASE_URL } from '@/config';

export default {
  name: 'Avatar',
  components: {
      IonAvatar
  },

  data() {
    return {
      user: null,
    };
  },

  mounted() {
    this.$store.dispatch('auth/whoami')
      .then(user => {
        this.user = user;
      })
      .catch(console.error);
  },

  computed: {
    ...mapGetters({
      'isAuthenticated': 'auth/isAuthenticated',
    }),

    avatar() {
      if (!this.user) {
        return;
      }

      const url = new URL(`/api/1.0/social/profiles/${this.user.id}/avatar/`, API_BASE_URL);
      
      return url.toString();
    },

    alt() {
      if (!this.user) {
        return;
      }

      const alt = this.user.username;
      if (!this.user) {
        return;
      }

      return alt;
    },
  },

  methods: {
    goToProfile() {
      this.$router.push(`/profile`);
    },

    onLogout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push('/');
        });
    },
  },
}
</script>

<style scoped>
  ion-avatar {
    --border-radius: 100px;
    width: 50px;
    height: 50px;
  }
</style>