<template>
    <ion-tab-bar slot="bottom">
        <ion-tab-button tab="matches" href="/matches/schedule" :class="{ active: active === 'matches' }">
            <ion-icon src="/img/icons/ui/game-icon.svg" class="tab-icon"></ion-icon>
            <ion-label>Matches</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="community" href="/community/feed" :class="{ active: active === 'community' }">
            <ion-icon src="/img/icons/ui/24-support-icon.svg" class="tab-icon"></ion-icon>
            <ion-label>Community</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="invite" href="/invite/friend" :class="{ active: active === 'invite' }">
            <ion-icon src="/img/icons/ui/profile-user-icon.svg" class="tab-icon"></ion-icon>
            <ion-label>Invite A Friend</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</template>

<script>
import { IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/vue';

export default {
  name: 'FooterTabBar',

  components: {
    IonTabBar, IonTabButton, IonIcon, IonLabel
  },
  
  props: {
      active: {
          type: String,
          default: '',
      }
  }
}
</script>

<style scoped>
    ion-tab-bar {
        height: 80px;
    }

    ion-tab-button {
        color:#8F99A3;
    }

    ion-tab-button.active {
        color:#044784;
    }

    ion-label {
        margin-top: 3px;
    }

    .tab-icon, .item-icon {
        font-size: 24px;
    }
</style>