<template>
    <ion-grid>
        <ion-row>
            <ion-col>
                <img src="/img/banners/banner-home-2.png"  class="banner-image">
            </ion-col>
        </ion-row>
    </ion-grid>
    <!---TODO remove banner for matches once ready-->
    <!---
    <ion-list :inset="true" v-for="match in matches">
        <ion-item :class="{ 'match-item': true, [match.status]: true }">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-grid class="match-item-time">
                            <ion-row>
                                <ion-col>
                                    <ion-icon src="/img/icons/ui/calendar-icon.svg" class="item-icon"></ion-icon> 
                                    <ion-label>{{ match.date }}</ion-label>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <ion-icon src="/img/icons/ui/clock-icon.svg" class="item-icon"></ion-icon>
                                    <ion-label>{{ match.time }}</ion-label>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-col>
                    <ion-col class="ion-align-items-center ion-justify-content-end">
                        <ion-button
                            type="submit" 
                            shape="round" 
                            expand="block" 
                            class="btn-check-in"
                            v-if="match.button === 'btn-check-in'"
                        >Check In</ion-button>
                        <ion-button
                            type="submit" 
                            shape="circle" 
                            expand="block" 
                            class="btn-msg"
                            v-if="match.button === 'btn-msg'"
                        ><ion-icon src="/img/icons/ui/msg-chat-circle.svg" class="item-icon" slot="icon-only"></ion-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
    </ion-list>
    -->
</template>

<script>
import { IonGrid, IonCol, IonRow, IonList, IonItem, IonButton, IonButtons, IonLabel, 
    IonIcon } from '@ionic/vue';

export default {
  name: 'MatchSchedule',
  components: {
    IonGrid, IonCol, IonRow, IonList, IonItem, IonButton, IonButtons, IonLabel, 
    IonIcon
  },

  computed: {
    matches(){
        // TODO: add api connection to pull data
        return [
            { date: '12/14/23', time: '10:00 - 11:00', status: 'green', button: 'btn-check-in' },
            { date: '12/14/23', time: '10:00 - 11:00', status: 'yellow', button: 'btn-msg' },
            { date: '12/14/23', time: '10:00 - 11:00', status: 'red', button: 'btn-check-in' },
        ];
    }
  }
}
</script>

<style scoped>
    .match-item {
        height: 88px;
        --background: #F7F7F7;
        border-radius: 16px;
        margin-bottom: 13px;
    }

    .match-item ion-grid{
        padding-top: 3px;
    }

    .match-item ion-col{
        padding-left: 0px;
        padding-right: 0px;
    }

    .item-native {
        --padding-left: 0px !important;
    }

    .match-item.green {
        border-left: 8px solid #2ECC71;
    }

    .match-item.red {
        border-left: 8px solid #FF3B30;
    }

    .match-item.yellow {
        border-left: 8px solid #F1CE1E;
    }

    .match-item ion-label {
        margin-left: 8px;
    }

    .match-item-time {
        min-width: 168px;
    }

    .item-icon {
        font-size: 24px;
    }

    ion-button {
        --height: 32px;
    }

    .btn-check-in, .btn-msg {
        width: auto;
        height: 50px;
    }

    ion-button[shape=circle] {
        --border-radius: 50%;
        width: 50px;
        height: 50px;
    }

</style>