import { createRouter, createWebHistory } from '@ionic/vue-router';
import { isPlatform } from '@ionic/vue';
import Home from '@/pages/Home.vue';
import Download from '@/pages/Download.vue';
import Login from '@/pages/Login.vue';
import LoginVerify from '@/pages/LoginVerify.vue';
import Subscribe from '@/pages/subscription/Subscribe.vue';
import Matches from '@/pages/match/Matches.vue';
import Community from '@/pages/community/Community.vue';
import Settings from '@/pages/community/views/Settings.vue';
import Support from '@/pages/community/views/Support.vue';
import Faq from '@/pages/community/views/Faq.vue';
import Messages from '@/pages/community/views/Message.vue';
import MessageDetail from '@/pages/community/views/MessageDetail.vue';
import Feed from '@/pages/community/views/Feed.vue';
import Friends from '@/pages/community/views/Friends.vue'
import Search from '@/pages/community/views/Search.vue';
import Profile from '@/pages/user/views/Profile.vue';
import ProfileEdit from '@/pages/user/views/Edit.vue';
import MatchDetail from '@/pages/match/views/Match.vue';
import MatchSchedule from '@/pages/match/views/Schedule.vue';
import MatchOpen from '@/pages/match/views/Open.vue';
import MatchHistory from '@/pages/match/views/History.vue';
import Invite from '@/pages/invite/Invite.vue';
import InviteFriend from '@/pages/invite/views/Friend.vue';
import PageNotFound from '@/pages/404.vue';
import store from '@/store';

const requiresAuth = [
  '/login', '/login-verify', '/profile', '/profile/edit', '/invite/friend', '/subscribe', '/matches/schedule', '/matches/detail', 
  '/matches/history', '/community/feed', '/community/friends', new RegExp('/community/friend.*/'), '/community/search', '/community/settings', 
  '/community/support', '/community/faq', new RegExp('/community/message/detail.*/')
];

const routes = [
  { path: '/', component: Home },
  { path: '/home', component: Home },
  { path: '/download', component: Download },
  
  // Registration and Onboarding urls
  { path: '/login', component: Login },
  { path: '/login-verify', component: LoginVerify }, 

  // Start Private urls

  // Subscription urls
  { path: '/subscribe', component: Subscribe },

  // User URLS
  { path: '/profile', component: Profile },
  { path: '/profile/edit', component: ProfileEdit }, 

  // Match URLS
  {
    path: '/matches',
    component: Matches,
    children: [
      {
        path: '/', 
        redirect: '/schedule',
      },
      {
        path: 'schedule', 
        component: MatchSchedule,
      },
      {
        path: 'detail', 
        component: MatchDetail,
      },
      {
        path: 'open', 
        component: MatchOpen,
      },
      {
        path: 'history', 
        component: MatchHistory,
      }
    ]
  },

  // Invite URLs
  {
    path: '/invite',
    component: Invite,
    children: [
      {
        path: '/', redirect: '/friend',
      },
      {
        path: 'friend', component: InviteFriend,
      }
    ]
  },
  // End Private URLS

  // community
  {
    path: '/community',
    component: Community,
    children: [
      {
        path: '',
        redirect: '/community/feed',
      },
      {
        path: 'settings',
        component: Settings,
      },      
      {
        path: 'support',
        component: Support,
      },
      {
        path: 'faq',
        component: Faq,
      },
      {
        path: 'messages',
        component: Messages,
      },
      {
        path: 'message/detail/:id',
        component: MessageDetail,
      },
      {
        path: 'feed', 
        component: Feed,
      },
      {
        path: 'friends',
        component: Friends,
      },
      {
        path: 'friend/:id',
        component: Profile,
      },
      {
        path: 'search', 
        component: Search,
      },
    ]
  },
  
  // 404 catchall show download PWA homepage
  { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  // If user is using a desktop redirect to QR download page unless on /download
  if (isPlatform('desktop') && to.path !== '/download') {
    return '/download';
  }

  if(!requiresAuth.some(path => {
    if(typeof path === 'string' ? path === to.path : path instanceof RegExp && path.test(to.path)){
      return true; // if path contains regular expression use regex to search the path or if path is a string then compare it to current path
    }
  })){
    // If the regexpression or string search do not find a match return, if not then continue to whoami
    return;
  }

  // If a user is not logged in a 403 is returned and must be caught
  const user = await store.dispatch('auth/whoami').catch((err) => {});

  if (!user && to.path !== '/login') { // Stops infinite redirects to Login
    return '/login';
  } else if (user && to.path == '/login') { // If user is logged and on login page return them to matches/schedule
    return '/matches/schedule'
  }
});

export default router;
