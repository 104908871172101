<template>
    <ion-grid>
        <ion-row>
            <ion-col>
                <label class="ion-margin-horizontal small">{{label}}</label>
            </ion-col>
        </ion-row>  
        <ion-row>
            <ion-col>
                <IonTextarea       
                    :name="name"
                    label-placement="stacked"
                    fill="solid"
                    :placeholder="placeholder"
                    v-model="modelValue"
                    @input="onInput"
                    @ionFocus="onFocus"
                    @ionBlur="onBlur"
                    class="native-input ion-text-start dink-input"
                ></IonTextarea>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { IonCol, IonGrid, IonRow, IonTextarea } from '@ionic/vue';

export default {
    name: 'DefaultTextarea',

    components: {
        IonCol, IonGrid, IonRow, IonTextarea
    },

    props: {
        name: {
            type: String,
            default: '',
            required: true
        },
        label: {
            type: String,
            default: '',
            required: true
        },
        placeholder: {
            type: String,
            default: '',
            required: false
        },
        value: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            modelValue: this.modelValue
        };
    },

    emits:["update:modelValue","focus", "blur"],
    methods: {
        onInput(e) {
            this.$emit("update:modelValue", e.target.value);
        },
        onFocus(e) {
            this.$emit('focus');
        },
        onBlur(e) {
            this.$emit('blur');
        }
    },
    
    created() {
        this.modelValue = this.value;
    }
}
</script>

<style scoped>
label.small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}

ion-textarea {
    --background: var(--ion-color-step-50, #f2f2f2);
    --border-color: var(--ion-color-step-500, gray);
    --border-radius: 14px;
    --padding-start: 16px;
    --padding-end: 16px;
    min-height: 56px;
}
</style>