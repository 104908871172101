<template>
    <form>  
        <ion-grid class="ion-justify-content-center ion-margin-top">
            <ion-row class="ion-margin-bottom">
                <ion-col class="ion-padding-start card-element-container">
                    <form class="card-element-form">
                        <div style="width: 20em" id="card-element"></div>
                    </form>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>    
</template>

<script>
import { STRIPE_PUBLISHABLE_KEY } from '@/config';
import { IonCol, IonGrid, IonRow, IonButton, loadingController } from '@ionic/vue';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { ref } from 'vue';
import DefaultInput from '@/components/forms/DefaultInput.vue';
import { loadStripe } from '@stripe/stripe-js';
import api from '@/services/api';

export default {
  name: 'BillingForm',

  components: {
    IonCol, IonGrid, IonRow, IonButton, DefaultInput, loadingController, ref
  },

  props: {
    package: {
      type: String,
      default: null,
      required: true,
    },
    coupon: {
      type: String,
      default: '',
    },
    annual: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      user: null,
      stripe: null,
      elements: null,
      paymentElement: null,
      error: null,
    }
  },

  expose: ['submit'],

  async mounted() {
    this.user = await this.whoami();
    this.stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    this.elements = this.stripe.elements();
    const paymentElement = this.elements.create('card', {
      style: {
        base: {
          fontSize: '16px',
          color: '#32325d'
        },
      },
    });
    paymentElement.mount('#card-element');
  },

  emits: ['loginCallback'],

  computed: {
    ...mapGetters({
      busy: 'app/busy',
    })
  },

  methods: {
    ...mapMutations({
      incRequest: 'app/INC_REQUESTS',
      decRequest: 'app/DEC_REQUESTS',
    }),
    ...mapActions({
      whoami: 'auth/whoami',
    }),

    // Implemented flow 2 (more correct) from:
    // https://stackoverflow.com/questions/62454487/stripe-v3-setupintents-and-subscriptions
    async submit() {
      if (this.busy) {
          return;
      }
      // NOTE: we take manual control of the spinner because we are making a call
      // to an external service (stripe).
      this.incRequest();

      try {
        const card = this.elements.getElement('card');
        const {
          error: paymentError,
          paymentMethod,
        } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: card,
        });

        if (paymentError) {
          throw paymentError;
        }

        await api.post(
          `/payment/members/${this.user.id}/payment/`, paymentMethod);

        const r = await api.post(`/payment/members/${this.user.id}/package/`, {
          payment: paymentMethod,
          package_id: this.package,
          interval: (this.annual)? 'year' : 'month',
          coupon_code: this.coupon
        });

        const clientSecret = r.data['client_secret'];
        if (clientSecret) {
          const {
            error: confirmError,
          } = await this.stripe.confirmCardPayment(clientSecret);

          if (confirmError) {
            throw confirmError;
          }
        }

        this.$emit('billingDetailsCallback', { r });

      } catch (err) {
        console.error(err);

      } finally {
        this.decRequest();
      }
    },
  },
}
</script>

<style scoped>
ion-col {
    background-color: #f2f2f2;
    border: solid 1px #f2f2f2;
    color: #000;
    text-align: center;
    border-radius: 12px;
    height: 80px;
}
ion-radio {
    display: flex;
    --color: #044784;
    --color-checked: #fff;
}
ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
}
ion-radio.radio-checked::part(container) {
    background: #044784;
    border-color: transparent;
}
ion-radio.ios::part(container) {
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 12px;
}
ion-radio.radio-checked.ios::part(container) {
    border-color: #044784;
    border-color: transparent;
}
ion-radio.radio-checked::part(mark) {
    width: 4px;
    height: 8px;
    margin-left: 1px;
    margin-bottom: 1px;
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;
    transform: rotate(45deg);
}
ion-radio.label-text-wrapper {
    -webkit-margin-start: 12px;
    margin-inline-start: 12px
}
.no-background {
    background-color: #fff;
    border: none;
}
label.small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}
.card-element-container {
    height: 57px;
    display: flex;
    align-items: center;
}
#custom-button {
    height: 30px;
    outline: 1px solid grey;
    background-color: green;
    padding: 5px;
    color: white;
}
#card-error {
    color: red;
}
</style>