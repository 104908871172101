<template>
    <div class="feed-bubble ion-margin-bottom ion-no-padding">
        <ion-grid class="feed-grid ion-padding-vertical">
            <ion-row>
                <ion-col size="3">
                    <template v-if="typeof id === 'string'">
                        <img 
                            :src="getAvatarSource(id)"
                            alt="24/7 Dink Avatar"
                            width="50"
                            height="50" class="feed-image ion-justify-content-center"> 
                    </template>
                </ion-col>
                <ion-col class="feed-details" size="7">
                    <span class="feed-title">{{ title }}</span>
                    <div class="feed-subtitle" v-if="subtitle">{{ subtitle }}</div>
                    <div class="feed-badges">
                        <!-- TODO wrap up badges -->
                        <!--<ion-icon v-for="badge in badges" :key="badge" :src="getImageSource(badge)" class="feed-icon"></ion-icon>-->
                    </div>
                </ion-col>
                <ion-col class="ion-justify-content-end ion-align-items-center" size="2">
                    <ion-button
                        type="submit" 
                        shape="circle" 
                        expand="block" 
                        class="btn-feed"
                        v-if="icons.goto"
                        @click.stop="goToProfile(id)"
                    >
                        <ion-icon src="/img/icons/ui/goto-arrow-icon.svg" class="feed-icon" slot="icon-only"></ion-icon>
                    </ion-button>
                    <ion-button 
                        type="submit" 
                        shape="circle" 
                        expand="block" 
                        class="btn-feed disable"
                        v-if="icons.msg"
                        @click.stop=""
                    >
                        <ion-icon src="/img/icons/ui/msg-chat-circle.svg" class="feed-icon" slot="icon-only"></ion-icon>
                    </ion-button>
                    <ion-button
                        type="submit" 
                        shape="circle" 
                        expand="block" 
                        class="btn-feed"
                        v-if="icons.friendAdd"
                        @click.stop="handleAddFriendClick(id)"
                    >
                        <ion-icon src="/img/icons/ui/profile-add-icon.svg" class="feed-icon" slot="icon-only"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script>
import { IonGrid, IonCol, IonRow, IonButton, IonButtons, IonIcon } from '@ionic/vue';
import { mapActions } from 'vuex';

export default {
  name: 'FeedBubble',

  components: {
    IonGrid, IonCol, IonRow, IonButton, IonButtons, IonIcon
  },

  props: {
      id: {
          type: String,
          default: '',
      },
      title: {
          type: String,
          default: '',
      },
      subtitle: {
          type: String,
          default: '',
      },
      badges: {
        type: Array
      },
      image: {
          type: String,
          default: '',
      },
      icons: {
        type: Object,
        required: true,
        default: function () {
            return {
                goto: {
                    type: Boolean,
                    default: true,
                },
                msg: {
                    type: Boolean,
                    default: true,
                },
                friendAdd: {
                    type: Boolean,
                    default: true,
                },
            };
        },
    },
  },

  emits:["addFriendCallback"],
  methods: {
    ...mapActions({
        addFriend: 'friends/add',
    }),

    getImageSource(badge) {
      return `/img/icons/ui/${badge}.svg`;
    },
    
    getAvatarSource(id){
      return `/api/1.0/social/profiles/${id}/avatar/`;
    },

    goToMessages(id) {
        this.$router.push(`/community/message/detail/${id}`);
    },

    goToProfile(id) {
        this.$router.push(`/community/friend/${id}`);
    },

    async handleAddFriendClick(id) {
        const r = await this.addFriend({ 
            friend: id 
        });

        this.$emit('addFriendCallback', r);
    },
  }
}
</script>

<style scoped>
ion-button[shape=circle] {
    --border-radius: 50%;
    --padding-end: 0px;
    --padding-start: 0px;
    min-height: 32px;
    min-width: 32px;
}

.feed-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
}

.btn-feed {
    width: 32px;
    height: 32px;
}

.feed-icon {
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;
}

.feed-bubble {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    gap: 8px;
    width: 100%;
    height: 95px;
    background: #F7F7F7;
    border-radius: 20px;
}

.feed-grid {
    width: 100%;
}

.feed-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feed-badges {
    margin-top: 8px;
}

.feed-image {
    border-radius: 50px;
    min-width: 64px;
    min-height: 64px;
}

</style>