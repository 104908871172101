<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <ProfileToolbar
                :id="profile.id"
                :showMessageBtn=this.messageBtn
                :showEditBtn=this.editBtn
                defaultHref="/matches/schedule"
            ></ProfileToolbar>
        </ion-header>            
        <ion-content class="ion-padding">
            <Profile v-if="profile.id"
                :id="profile.id"
                :name=username
                :bio="profile.biography"
                :rank="rating"
                :games="games"
                court="Fishers"
            ></Profile>
            <div v-else class="ion-margin-top ion-margin-start ion-padding-start">
                Profile does not exist.
            </div>
            <ion-button
                type="submit" 
                shape="round" 
                expand="block"
                class="logout-btn"
                v-if="logoutBtn"
                @click="logout()">Logout</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip, IonIcon, IonButton } from '@ionic/vue';
import { ref, computed, onMounted  } from 'vue';
import { mapGetters, mapActions, useStore } from 'vuex';
import ProfileToolbar from '@/components/ui/ProfileToolbar.vue';
import SocialChip from '@/components/ui/nav/SocialChip.vue';
import Profile from '@/components/user/Profile.vue';

export default {
    name: 'FriendProfile',
    components: {
        IonPage, IonNav, ProfileToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
        IonChip, IonIcon, SocialChip, Profile, IonButton, ref, mapGetters, mapActions, useStore
    },

    data() {
      return {
        profile: {},
        user: null,
        messageBtn: false,
        editBtn: false,
        logoutBtn: false
      }
    },

    async mounted() {
        const store = useStore();
        const profile = ref(null);
        const id = this.$route.params.id || (await this.whoami()).id; // If id not in params user logged in user id

        if(this.$route.params.id){
            this.messageBtn = false;
        } else {
            this.editBtn = true;
            this.logoutBtn = true;
        }

        const response = await this.getProfile(id);
        this.profile = response;
    },

    computed: {
        rating() {
            return this.profile.rating?.rating || "0";
        },

        games() {
            return this.profile.user?.stats || "0";
        },

        username() {
            return this.profile.user?.username || "";
        },
    },

    methods: {
        ...mapActions({
            getProfile: 'friends/getProfileById',
        }),

        ...mapActions({
            whoami: 'auth/whoami',
        }),

        async logout() {
            await this.$store.dispatch('auth/logout');

            this.$router.push('/login');
        },
    }
}
</script>

<style scoped>
.btn-header {
    width: auto;
}

.logout-btn {
    --background: #FF3333;
}
</style>