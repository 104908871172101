import axios from 'axios';
import { API_BASE_URL, API_VERSION } from '@/config';
import store from '@/store';
import router from '@/router';

const baseURL = (new URL(`/api/${API_VERSION}`, API_BASE_URL)).toString();
const api = axios.create({
  baseURL,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
});

api.interceptors.request.use((config) => {
  if (!config.disableSpinner) {
    store.commit('app/INC_REQUESTS');
  }

  return config;
}, (err) => {
  if (!err.config.disableSpinner) {
    store.commit('app/DEC_REQUESTS');
  }
  return Promise.reject(err);
});

api.interceptors.response.use((r) => {
  if (!r.config.disableSpinner) {
    store.commit('app/DEC_REQUESTS');
  }

  return r;
}, (err) => {
  if ([401].includes(err.response?.status)) {
    store.commit('auth/SET_USER', null);
    router.push('/login');
  }

  if (!err.config.disableSpinner) {
    store.commit('app/DEC_REQUESTS');
  }

  return Promise.reject(err);
});

export default api;
