<template>
    <ion-page>
        <Header
            logo="white"
            type="logo"
        ></Header>
        <ion-content class="ion-padding">
          <ion-grid>
            <ion-row>
              <ion-col class="ion-align-items-center">
                <img src="/img/screenshots/hero-phone.png" 
                width="375" 
                height="641" 
                alt="Install 24/7 Dink"
                class="hero-image">
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-align-items-center">
                <h1>Page not found</h1>
              </ion-col>
            </ion-row>            
          </ion-grid>  
        </ion-content>
    </ion-page>
</template>

<script>
import { IonNav, IonPage, IonContent, IonCol, IonGrid, IonRow  } from '@ionic/vue';
import Header from '@/components/Header.vue';

export default {
    name: 'Page not found',
    components: {
      Header, IonNav, IonPage, IonContent, IonCol, IonGrid, IonRow
    }
  }
</script>

<style scoped>
.hero-image {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
</style>