import api from '@/services/api';

const auth = {
  namespaced: true,

  state() {
    return {
      user: null,
    };
  },

  getters: {
    isAuthenticated(state) {
      return Boolean(state.user);
    },
  },

  actions: {
    async login({ commit }, { email, mobile }) {
      const credentials = email ?  { email } : { mobile } ;

      const r = await api.post('/security/auth/login/', credentials );

      return r;
    },

    async verifyCode({ commit }, params) {
      const r = await api.post('/security/auth/login/', params);
      
      // If status is 200 user exist set user data
      if(r.data && (200 <= r.status && r.status < 300)) {
        commit('SET_USER', r.data);
      }

      return r;
    },
    
    async checkUnique({ commit }, params) {
      const r = await api.get("/user/check/", {
        params: params,
        disableSpinner: true
      })

      return r;
    },

    async register({ commit }, params) {
      const r = await api.post('/user/users/', params);
      
      return r;
    },

    async logout({ commit }) {
      const r = await api.delete('/security/auth/login/');
      commit('SET_USER', false);

      return r;
    },

    async whoami({ commit, state }) {
      if (state.user === false) {
        return null;
      } else if (state.user !== null) {
        return state.user;
      }

      try {
        const r = await api.get('/user/whoami/');
        commit('SET_USER', r.data);
        return state.user;

      } catch (err) {
        commit('SET_USER', false);
        throw err;
      }
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;

      const socket = this.$socket.io;
      const manager = socket.io;

      if (!socket || !manager) {
        return;
      }

      if (user) {
        socket.connect();
        manager.reconnection(true);
      } else {
        socket.disconnect();
        manager.reconnection(false);
      }
    },

    UPDATE_USER(state, user) {
      state.user = user;
    }
  }
};

export default auth;
