<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <AvatarToolbar></AvatarToolbar>
            <CommunityChip
                active="settings"
            ></CommunityChip>
        </ion-header>            
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <img src="/img/banners/banner-settings.png"  class="banner-image">
                    </ion-col>
                </ion-row>                                                   
            </ion-grid>
            <!--TODO connect settings to backend once live--> 
        </ion-content>
    </ion-page> 
</template>

<script>
import { IonPage, IonNav, IonContent, IonIcon, IonGrid, IonCol, IonRow, IonList, IonItem, IonLabel, IonHeader, 
    IonFooter, IonChip, IonToggle  } from '@ionic/vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import CommunityChip from '@/components/ui/nav/CommunityChip.vue';

export default {
    name: 'Settings',
    components: {
        IonPage, IonNav, AvatarToolbar, IonContent, IonIcon, IonGrid, IonCol, IonRow, 
        IonList, IonItem, IonLabel, IonHeader, IonFooter, IonChip, CommunityChip, IonToggle 
    }
}
</script>

<style scoped>
ion-list {
    border-radius: 16px;
    --background: #d88686;
    width: 97%;
}

ion-item {
    --background: #F7F7F7;
}

ion-item:last-child ion-label {
    --border-width: 0;
    --border-color: transparent;
    border-bottom: 0px;
}

ion-label {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 12px;
}

.item-icon {
    background: #044784;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
    gap: 4px;
    width: 22px;
    height: 22px;
    margin-bottom: 12px;
}

.item-arrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 25px;
    height: 12px;
    padding-bottom: 12px;
    padding-right: 19px;
}

ion-footer {
    position: fixed; 
    bottom: 0;
}
</style>