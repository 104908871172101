<template>
    <ion-page>
        <Header
            text="Messages"
            type="text"
            defaultHref="/subscribe"
            :showLogout=false
            @backButtonCallback="backButtonCallbackHandler"
        ></Header>           
        <ion-content class="ion-padding">
            <CommunityMessages></CommunityMessages>       
        </ion-content>
    </ion-page>   
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonChip } from '@ionic/vue';
import Footer from '@/components/FooterButton.vue';
import Header from '@/components/Header.vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import CommunityChip from '@/components/ui/nav/CommunityChip.vue';
import CommunityMessages from '@/components/community/MessageList.vue';

export default {
    name: 'Comunity Messages',
    components: {
        IonPage, IonNav, Header, Footer, AvatarToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, 
        IonChip, CommunityMessages, CommunityChip
    },

    emits:['backButtonCallback'],
    methods: {
        backButtonCallbackHandler(r) {
            this.$router.back();
        },
    }
}
</script>

<style scoped>
ion-button {
    width: auto;
}

.btn-header {
    width: auto;
    --background: $ffffff;
}

.btn-add-schedule {
    --background: #044784;
    --color: #fff;
    height: 32px;
    margin-left: auto;
}

h2 {
    margin-top: 0px;
    line-height: 30px;
}
</style>