<template>
    <ion-page>
    <div v-if="page.panel.showPackages" class="dink247-verify-container">
      <PackageSelection
        @packageSelectionCallback="handlePackageSelectionCallback"
      ></PackageSelection>
    </div>
    <div v-if="page.panel.showBillingDetails" class="dink247-verify-container">
      <BillingDetails
        :packageSelected = "this.formData.plan.value"
        :coupon = "this.formData.coupon.value"
        @billingDetailsCallback="handleBillingDetailsCallback"
        @backButtonCallback="handleBackButtonCallback"
      ></BillingDetails>
    </div>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import PackageSelection from '@/components/onboarding/PackageSelection.vue';
import BillingDetails from '@/pages/subscription/BillingDetails.vue';

export default {
  name: 'Subscribe',
  components: {
    IonContent, IonPage, PackageSelection, BillingDetails
  },

  data() {
    return {
      formData:{
        plan: {
          value: null,
          rules: [
            (v) => !!v || 'Package is a required field',
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        coupon: {
          value: null,
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
      },
      page: {
        panel:{
            showPackages: true, 
            showBillingDetails: false
        }
      }
    }
  },
  
  methods: {
    handleBackButtonCallback(r){
      // Hide panels not in use
      this.hideAllPanels();
      if(r.page == '/subscribe'){
        this.page.panel.showPackages = true;
      } 
    },

    handlePackageSelectionCallback(r){
      // Hide panels not in use
      this.hideAllPanels();
      this.formData.plan.value = r.formData.plan.value;
      this.formData.coupon.value = r.formData.coupon.value;

      // Show billing details
      this.page.panel.showBillingDetails = true;
    },

    handleBillingDetailsCallback(){
      // TODO remove this push once we have a new location to push the users
      this.$router.push({ path: '/invite/friend', query: { message: 'success' } });
    },
    
    hideAllPanels() {
      this.page.panel.showPackages = false;
      this.page.panel.showBillingDetails = false;
    }
  }
}
</script>

<style scoped>
.dink247-verify-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>