<template>
    <ion-page>
        <Header
            text="Dinken Dave"
            subTitle="Active now"
            type="message"
            image="/img/icons/icon-167x167.png"
            defaultHref="/subscribe"
            :showLogout=false
            @backButtonCallback="backButtonCallbackHandler"
        ></Header>        
        <ion-content class="ion-padding">
            <!--TODO connect to backend api-->
            <ion-grid>
                <!---<ion-row>
                    <ion-col>
                        <div class="message-box">
                            <div class="message-avatar">
                                <img src="/img/icons/icon-167x167.png"
                                    alt="24/7 Dink Avatar"
                                    width="32"
                                    height="32" class="ion-justify-content-center">
                            </div>
                            <div class="message-bubble received">Let me be the first to Welcome you to 247 Dink.</div>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <div class="message-box">
                            <div class="message-avatar">
                                <img src="/img/icons/icon-167x167.png"
                                    alt="24/7 Dink Avatar"
                                    width="32"
                                    height="32" class="ion-justify-content-center">
                            </div>
                            <div class="message-bubble received">If you have any questions please just ask, I am here 24/7.</div>                        
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <div class="message-box">
                            <div class="message-avatar">
                                <img src="/img/icons/icon-167x167.png"
                                    alt="24/7 Dink Avatar"
                                    width="32"
                                    height="32" class="ion-justify-content-center">
                            </div>
                            <div class="message-bubble received message-animation">
                                <div class="dot-container">
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                </div>       
                            </div>
                        </div>
                    </ion-col>
                </ion-row>-->                                                             
            </ion-grid>
        </ion-content>
        <ion-footer class="ion-no-border" style="position: fixed; bottom: 0;">
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-justify-content-center">
                        <DefaultInput
                            name="msg"
                            label=""
                            placeholder="Type yours message here"
                            v-model="this.formData.message.value"
                            class=""
                            :value="this.formData.message.value"
                            @keyup.enter="handleMessageSend"
                        ></DefaultInput>       
                        <ion-button
                            type="submit" 
                            shape="circle" 
                            expand="block" 
                            class="btn-message-send"
                            :onClick="handleMessageSend"
                        ><ion-icon src="/img/icons/ui/send-icon.svg" class="item-icon" slot="icon-only"></ion-icon></ion-button>           
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-footer>
    </ion-page>        
</template>

<script>
import { IonPage, IonNav, IonContent, IonIcon, IonTabs, IonGrid, IonCol, IonRow, IonFooter, IonChip, IonButton } from '@ionic/vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';
import DefaultInput from '@/components/forms/DefaultInput.vue';
import FormVerification from '@/composables/verification.js'
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import CommunityChip from '@/components/ui/nav/CommunityChip.vue';
import { mapActions } from 'vuex';

const { displayErrorMessage, validateForm } = FormVerification();

export default {
    name: 'Message Detail',
    components: {
        IonPage, IonNav, Header, Footer, DefaultInput, FormVerification, AvatarToolbar, IonContent, IonIcon, IonTabs, IonGrid, 
        IonCol, IonRow, IonFooter, IonChip, IonButton, CommunityChip
    },

    data() {
      return {
        formData: {
            message: {
                rules: [
                    (v) => !!v || 'Message is a required field',
                ],
                error: {
                    messages: []
                },
                classes: {
                    'error': false
                }
            },
            userid: {
                rules: [
                    (v) => !!v || 'User Id is a required field',
                ],
                error: {
                    messages: []
                },
                classes: {
                    'error': false
                }
            },
        }
      }
    },

    async mounted() {
        if(this.$route.params.id){
            this.formData.userid.value = this.$route.params.id;
        }   
    },

    emits:['backButtonCallback'],
    methods: {
        ...mapActions({send:'messages/send'}),
        backButtonCallbackHandler(r) {
            this.$router.back();
        },

        async handleMessageSend(){
            if(!validateForm(this.formData)){
                return false;
            }

            var options = {
                body: this.formData.message.value,
                rcpt_id: this.formData.userid.value
            }

            const r = await this.send(options).catch((r) => {
                this.errorMessage = `Error sending message`;
            });

            if(r?.status == 201) {
                this.formData.message.value = "";
                return true;
            } else {
                this.errorMessage = `Error sending message`;
                return false;
            }
        }
    }
}
</script>

<style scoped>
ion-button {
    width: auto;
}

.btn-header {
    width: auto;
    --background: $ffffff;
}

.btn-add-schedule {
    --background: #044784;
    --color: #fff;
    height: 32px;
    margin-left: auto;
}

h2 {
    margin-top: 0px;
    line-height: 30px;
}

.message-box {
    display: flex;
    width: 100%;
}

.message-avatar {
    display: flex;
    align-items: flex-end;
    border-radius: 100px;
    padding-bottom: 9px;
}

.message-avatar img {
    border-radius: 100px;
    width: 32px;
    height: 32px;
}

.message-bubble {
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    margin-bottom: 7px;
    width: auto;
    max-width: 85%;
    border-radius: 100px;
}

.message-bubble.received {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    background: #F7F7F7;
    flex: none;
    flex-grow: 0;
}

.message-bubble.sent {
    background: #044784;
    color: #fff;
    margin-left: auto;
}

ion-button[shape=circle] {
    --border-radius: 50%;
    width: 50px;
    height: 50px;
}

.btn-message-send {
    margin-top: 22px;
}

.dot-container {
  display: flex;
  justify-content: space-between;
  width: 32px;
  height: 8px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: dot-animation 1.2s infinite ease-in-out alternate;
}

.dot:nth-child(1) {
  background-color: #F7F7F7;
  animation-delay: 0.0s;
}

.dot:nth-child(2) {
  background-color: #C4C4C4;
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  background-color: #A4A4A4;
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  background-color: #8F8F8F;
  animation-delay: 0.9s;
}

@keyframes dot-animation {
  from {
    background-color: #F7F7F7;
  }
  to {
    background-color: #8F8F8F;
  }
}
</style>