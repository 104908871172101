<template>
    <ion-page>
        <Header
            logo="white"
            type="logo"
            defaultHref="/login"
            @backButtonCallback="backButtonCallbackHandler"
        ></Header>
        <ion-content class="ion-padding" scroll-y="false">
            <h2 class="ion-text-center">Enter 6 Digit Verification Code</h2>
            <VerifyForm
                :count=6
                :email="emailAddress"
                :phoneNumber="mobile"
                :initial="initial"
                ref="VerifyFormRef"
                @redirectCallback="backButtonCallbackHandler"
            ></VerifyForm>
        </ion-content>
        <Footer
            id="footer"
            buttonText="Verify"
            @click="onVerifySubmit"
        ></Footer>
    </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import { mapActions } from 'vuex';
import { ref } from 'vue';
import VerifyForm from '@/components/login/VerifyForm.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';

const VerifyFormRef = ref(null);

export default {
  name: 'Login Verify',

  components: {
    IonPage, Header, Footer, IonContent, VerifyForm, ref
  },

  props: {
        user: {
            phone: {
                type: String,
                default: ''
            },
            email: {
                type: String,
                default: ''
            }    
        },
        mobile: {
            type: String,
            default: ''
        },
        emailAddress: {
            type: String,
            default: ''
        },
        initial: {
            type: String,
            default: null,
        },
    },

  emits:["backButtonCallback"],    
  methods: {
    async onVerifySubmit() {
        this.$refs.VerifyFormRef.onVerify();
    },
    
    backButtonCallbackHandler(r) {
        this.$emit('backButtonCallback', r);
    }
  }
}
</script>