<template>
    <div class="ion-margin-top chip-nav">
        <ion-chip :class="{ active: active === 'feed' }" :onClick="handleFeedClick">Feed</ion-chip>
        <ion-chip :class="{ active: active === 'friends' }" :onClick="handleFriendsClick">Friends</ion-chip>
        <ion-chip :class="{ active: active === 'search' }" :onClick="handleSearchClick">Search</ion-chip>
    </div>
</template>

<script>
import { IonChip } from '@ionic/vue';

export default {
  name: 'SocialChip',

  components: {
    IonChip
  },
  
  props: {
      active: {
          type: String,
          default: '',
      }
  },

  methods: {
    async handleFeedClick(){
        this.$router.push('/community/feed');
    },
    async handleFriendsClick(){
        this.$router.push('/community/friends');
    },
    async handleSearchClick(){
        this.$router.push('/community/search');
    }
  },
}
</script>