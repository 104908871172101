<template>
    <ion-radio-group v-model="selectedValue" @change="planSelected(plan)">
        <ion-grid class="ion-justify-content-center ion-margin-top">
            <div v-if="packages"> 
                <ion-row class="ion-margin-bottom" v-for="plan in packages" :key="plan.id" @click="selectPackage(plan)">
                    <ion-col :class="{ selected: plan.selected }">
                        <ion-grid class="">
                            <ion-row class="">
                                <ion-col class="ion-justify-content-between">
                                    <ion-radio label-placement="end" justify="start" :key="plan.id" :value="plan.id">{{plan.name}}</ion-radio>
                                    <div v-if="plan.discounted && plan.year_price !== 'Free'">
                                        <span class="price discounted">{{plan.year_price}}</span>
                                        <span class="price ion-margin-start">{{plan.discounted.year_price}}</span>
                                    </div>
                                    <div v-else>
                                        <span class="price">{{plan.year_price}}</span>
                                    </div>
                                </ion-col>
                            </ion-row>
                            <ion-row class="">
                                <ion-col class="ion-text-left">
                                    <ul class="ion-no-margin ion-padding-horizontal">
                                        <li v-for="item in plan.features" :key="item">{{item.desc}}</li>
                                        <li v-if="plan.advanced_booking">Court Booking ( {{ plan.advanced_booking }} Days in Advance )</li>
                                        <li v-if="plan.history_retention">Historical Play Data - {{ plan.history_retention }} Days</li>
                                    </ul>
                                </ion-col>                         
                            </ion-row>
                        </ion-grid>
                    </ion-col>
                </ion-row>
            </div>
        </ion-grid>
    </ion-radio-group>
</template>

<script>
import { IonCol, IonGrid, IonRow, IonRadio, IonRadioGroup, IonButton, IonButtons } from '@ionic/vue';
import { ref, computed, onMounted, onBeforeMount } from 'vue';
import { mapActions, useStore, mapGetters  } from 'vuex';
import FormVerification from '@/composables/verification.js'

const { formatDiplayPrice } = FormVerification();

export default {
    name: 'PlanSelectionRadioInput',
    components: {
        IonCol, IonGrid, IonRow, IonRadio, IonRadioGroup, IonButton, IonButtons
    },

    props: {
        options: {
            type: Object,
            default: '',
        }
    },

    data() {
        return {
            selectedValue: this.selectedValue,
            packages: []
        }
    },

    watch: {
        options(options) {
            const store = useStore();

            this.$store.dispatch("payment/getPackages", options).then(fetchedPackages => {
                // Format data to be displayed
                fetchedPackages.forEach(plan => {
                    plan.selected = false
                    plan.year_price = formatDiplayPrice(plan.year_price)
                    if(plan.discounted){
                        plan.discounted.year_price = formatDiplayPrice(plan.discounted.year_price);
                    }
                });

                // Set default selected to Player+
                fetchedPackages[1].selected = true;
                this.selectedValue = fetchedPackages[1].id;

                this.$emit("update:selectedValue",this.selectedValue);

                this.packages = fetchedPackages;
            }).catch(error => {
                // Handle errors during the asynchronous operation
                console.error('Error fetching data:', error);
            });
        },
    },

    async mounted() {
        const store = useStore()
        try {
            const fetchedPackages = await store.dispatch("payment/getPackages", this.options);

            // Format data to be displayed
            fetchedPackages.forEach(plan => {
                plan.selected = false
                plan.year_price = formatDiplayPrice(plan.year_price)
            });

            // Set default selected to Player+
            fetchedPackages[1].selected = true;
            this.selectedValue = fetchedPackages[1].id;

            this.$emit("update:selectedValue",this.selectedValue);

            this.packages = fetchedPackages;
        } catch (error) {
            console.log('Error fetching packages:', error);
        }
    },

    emits: ['update:selectedValue'],
    methods: {
        selectPackage(planSelected){
            this.resetSelectedPlans();
            this.selectedValue = planSelected.id;
            planSelected.selected = true;
            this.$emit("update:selectedValue", planSelected.id);
        },

        resetSelectedPlans() {
            this.packages.forEach(plan => {
                plan.selected = false;
            })
        },
    }
}
</script>

<style scoped>
    ion-col {
        background-color: #f2f2f2;
        border: solid 1px #f2f2f2;
        color: #000;
        text-align: center;
        border-radius: 12px;
        height: auto;
    }
    ion-radio {
        display: flex;
        --color: #000;
        --color-checked: #7017ff;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
    }
    ion-radio::part(mark) {
        background: none;
        transition: none;
        transform: none;
        border-radius: 0;
    }
    ion-radio.radio-checked::part(container) {
        background: #fff;
        border-color: transparent;
    }
    ion-radio.ios::part(container) {
        width: 20px;
        height: 20px;
        border: 2px solid #000;
        border-radius: 12px;
    }
    ion-radio.radio-checked.ios::part(container) {
        border-color: #044784;
        border-color: transparent;
    }
    ion-radio.radio-checked::part(mark) {
        width: 4px;
        height: 8px;
        margin-left: 1px;
        margin-bottom: 1px;
        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: #7017ff;
        transform: rotate(45deg);
    }
    ion-radio.label-text-wrapper {
        -webkit-margin-start: 12px;
        margin-inline-start: 12px
    }
    .price {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
    }
    .price.discounted {
        text-decoration: line-through;
    }
    ul {
        padding-left: 18px;
    }
    li {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
    }
    .selected ion-col {
        background: transparent;
        border-color: transparent;
        color: #FFF;
    }
    .selected {
        background: radial-gradient(51.8% 49.8% at 36.25% 96.55%, #FFD600 0%, #FF6930 48.44%, #FE3B36 73.44%, rgba(254, 59, 54, 0) 100%), radial-gradient(182.65% 122.8% at 84.5% 113.5%, #FF1B90 24.39%, #F80261 43.67%, #ED00C0 68.85%, #C500E9 77.68%, #7017FF 89.32%);
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: rgba(255, 255, 255, 0.75) !important;
        --color: rgba(255, 255, 255, 0.75);
    }
    .selected > li {
        color: rgba(255, 255, 255, 0.75) !important;
    }
</style>