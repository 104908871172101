<template>
  <ion-footer class="ion-no-border">
        <ion-grid>
            <ion-row>
                <ion-col class="ion-justify-content-center">
                    <ion-button 
                    type="submit" 
                    shape="round" 
                    expand="block" 
                    class="ion-margin-bottom">{{buttonText}}</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-footer>
</template>

<script>
import { IonFooter, IonCol, IonGrid, IonRow, IonButton } from '@ionic/vue';

export default {
  name: 'FooterButton',

  components: {
     IonFooter, IonCol, IonGrid, IonRow, IonButton
  },
  
  props: {
      buttonText: {
          type: String,
          default: 'Next',
      }
  }
}
</script>

<style scoped>
ion-button {
    height: 50px;
}
</style>
