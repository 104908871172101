<template>
    <ion-toolbar class="ion-text-center ion-padding-horizontal">
        <ion-buttons slot="start">
            <Avatar
            class="ion-justify-content-center"></Avatar> 
        </ion-buttons>
        <ion-buttons slot="end" class="ion-justify-content-start">
            <ion-button
                class="btn-header"
                fill="clear"
                :onClick="goToMessages"
                v-if="showMessageButton">
                    <ion-icon src="/img/icons/ui/message-icon.svg" class="toolbar-icon badge-button" slot="icon-only"></ion-icon>
                    <ion-badge id="notifications-badge" color="primary" class="badge">{{ messageCount }}</ion-badge>
            </ion-button>
            <ion-button
                class="btn-header"
                fill="clear"
                :onClick="goToSettings"
            >
                <ion-icon src="/img/icons/ui/settings-icon.svg" class="toolbar-icon badge-button" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</template>

<script>
import { IonToolbar, IonButton, IonButtons, IonIcon, IonBadge } from '@ionic/vue';
import { mapActions, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'AvatarToolbar',

  components: {
    IonToolbar, IonButton, IonButtons, IonIcon, IonBadge, Avatar
  },

  data() {
    return {
        messageSwitch: false
    };
  },

  computed:{ 
    showMessageButton(){
        this.switchEnabled();
    },

    messageCount() {
        return 0;
    }
  },

  methods: {
    ...mapActions({
        switch: 'app/switch',
    }),

    async switchEnabled(){
        this.messageSwitch = await this.switch('message_user');
    },

    async goToMessages() {
        if (this.messageSwitch) {
            this.$router.push('/community/messages');
        }      
    },
    
    goToSettings() {
        this.$router.push('/community/settings');
    },
  }
}
</script>

<style scoped>
    ion-button {
        width: auto;
    }

    .btn-header {
        width: auto;
        min-height: 49px;
        --background: $ffffff;
    }

    .badge-button {            
        position: relative;
        width: 42px;
        top:1px;
        right: 1px;
        overflow: visible!important;
    }

   .badge {
        position: absolute;
        top: -3px;
        right: -3px;
        border-radius: 100%;
    }
</style>