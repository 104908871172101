import api from '@/services/api';

const user = {
    namespaced: true,

    actions: {
        async patch({ commit, rootState }, params){
            const user = rootState.auth.user;
            
            if (!user) {
                return;
            }

            const r = await api.patch(`/user/users/${user.id}/`, params)

            commit('auth/UPDATE_USER', r.data, { root: true });
            
            return r;
        },

        async inviteFriend({ commit }, params) {
            const r = await api.post('/user/invites/', params).catch(error => {
                if (error.response?.status) {
                    throw new Error(error.response.status);
                }
                throw error;
            });
            
            return r;
        },

        async search({ commit }, params ) {     
            const r = await api.get(`/user/users/`, { params: params });

            return r;   
        },
    }
}

export default user;