<template>
    <ion-page>
        <Header
            logo="white"
            type="logo"
        ></Header>
        <ion-content class="ion-padding">
            <ion-grid class="ion-padding-top ion-align-items-center">
              <ion-row>
                <ion-col class="ion-align-items-center">
                  <ion-img src="/img/screenshots/hero-phone.png" 
                    width="375" 
                    height="641" 
                    alt="Install 24/7 Dink"
                    class="hero-image"
                  ></ion-img>
                </ion-col>
                <ion-col class="ion-padding-start">
                  <ion-img
                    src="/img/qrs/app-247dink-com-download.png"
                    alt="Download 247 Dink"
                    width="375"
                    class="download-qr"
                  ></ion-img>
                </ion-col>
              </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonNav, IonPage, IonContent, IonCol, IonGrid, IonRow, IonHeader, IonImg  } from '@ionic/vue';
import Header from '@/components/Header.vue';

export default {
    name: 'Download247Dink',
    components: {
        IonNav, IonPage, IonContent, IonCol, IonGrid, IonRow, IonHeader, IonImg, Header
    }
  }
</script>

<style scoped>
.hero-image {
  max-width: 100%;
  height: auto;
}
.download-qr {
  width: 320px;
}
</style>