<template>
  <ion-page>
    <div v-if="page.panel.showLogin" class="dink247-login-container">        
      <LoginForm 
        @loginCallback="handleLoginCallback"
      ></LoginForm>
    </div> 
    <div v-if="page.panel.showVerify" class="dink247-verify-container">
      <LoginVerify
        :emailAddress="formData.email.value"
        :mobile="formData.mobile.value"
        :initial="verificationCode"
        @backButtonCallback="handleBackButtonCallback"
      ></LoginVerify>
    </div>
    <div v-if="page.panel.showDetails" class="dink247-verify-container">
      <MoreDetails
        :emailAddress="formData.email.value"
        :mobile="formData.mobile.value"
        :firstname="formData.firstname.value"
        :lastname="formData.lastname.value"
        :username="formData.username.value"
        @moreDetailCallback="handleMoreDetailCallback"
        @backButtonCallback="handleBackButtonCallback"
      ></MoreDetails>
    </div>
    <div
      v-if="errorMessage"
      class="error ion-margin-horizontal"
    >{{ errorMessage }}</div>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonButtons } from '@ionic/vue';
import { mapActions } from 'vuex';
import { ref, onUnmounted  } from 'vue';
import router from '@/router';
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import VerifyForm from '@/components/login/VerifyForm.vue';
import LoginVerify from '@/pages/LoginVerify.vue';
import MoreDetails from '@/components/onboarding/MoreDetails.vue';

export default {
  name: 'Login',
  components: {
    IonContent, IonPage, IonButton, IonButtons, Header, Footer, LoginForm, VerifyForm, LoginVerify, MoreDetails
  },

  beforeRouteLeave(to, from) {
      // Reset page
      this.hideAllPanels();
      this.page.panel.showLogin = true;

      // Clear form data
      this.resetFormData();
  },

  data() {
    const panel = {
      showLogin: true,
      showVerify: false,
      showDetails: false,
      showSkillQuestions: false
    }
    const params = new URLSearchParams(window.location.search);
    const verificationCode = params.get("code");
    let showLogin = true, showVerify = false, showDetails = false;

    if (verificationCode) {
      showLogin = false;
      showVerify = true;
    }

    return {
      formData:{
        firstname: {
          value: '',
          rules: [
            (v) => !!v || 'First name is required',
          ]
        },
        lastname: {
          value: '',
          rules: [
            (v) => !!v || 'Last name is required',
          ],
        },
        email: {
          value: '',
          rules: [
            (v) => validator.validate(v) || 'Email must be valid'
          ],
        },
        mobile: {
          value: '',
          rules: [

          ],
        },
        username: {
          value: '',
          rules: [
            (v) => !!v || 'Username is required',
          ],
        }
      },
      page: {
        panel:{
            showLogin: showLogin,
            showVerify: showVerify,
            showDetails: showDetails,
          }
      },
      errorMessage: "",
      verificationCode,
    }
  },

  methods: {
    ...mapActions({register:'auth/register'}),
    handleBackButtonCallback(r){
      // Hide panels not in use
      this.hideAllPanels();
      if(r.page == '/login'){
        this.page.panel.showLogin = true;
      } else if(r.page == '/more-details'){
        this.page.panel.showDetails = true;
      }
    },

    handleLoginCallback(r) {
      if(r){
        this.formData.email.value = r.email;
        this.formData.mobile.value = r.phone;

        // Hide and Display correct onboarding panel
        if(r.status == 404) {
          // Hide panels not in use
          this.hideAllPanels();

          // User does not exist
          this.page.panel.showDetails = true;  
        } else if (200 <= r.status && r.status < 300)  {
          // Hide panels not in use
          this.hideAllPanels();

          // Show the verify panel
          this.page.panel.showVerify = true;
        }
      } 
    },

    async handleMoreDetailCallback(r){
      // Hide panels not in use
      this.hideAllPanels();

      // Return the matching form data from more details
      this.formData = r;
      this.errorMessage = '';

      //Build registration object
      var options =  {
        'first_name': this.formData.firstname.value,
        'last_name': this.formData.lastname.value,
        'username': this.formData.username.value,
        'email': this.formData.email.value,
        'mobile': this.formData.mobile.value,
        'profile': {
          'birth_date': this.formData.dob.value
        }
      }

      //Make call to register user
      if(options != null){
          await this.register(options).then(response => {
            if(response.status){
              if(response.status == 400){
                this.errorMessage = `Error registering user: ${response.message}`;
              } else if((200 <= response.status < 300)) {
                // Hide panels not in use
                this.hideAllPanels();

                // Show the verify panel
                this.page.panel.showVerify = true;
              } else {
                this.errorMessage = `Error registering user please try again`;
                return false;
              }
            }            
          }).catch((r) => {
            var message = "Please try again.";
            
            if(r.response){
              if(r.response.data.mobile){
                message =  r.response.data.mobile;
              } else if (r.response.data.email) {
                message =  r.response.data.email
              }
            }

            this.errorMessage = `Error registering user please try again: ${message}`;
          });
      }
    },

    hideAllPanels() {
      this.page.panel.showLogin = false;
      this.page.panel.showVerify = false;
      this.page.panel.showDetails = false;
    },

    resetFormData(){
      Object.keys(this.formData).forEach((key) => { this.formData[key].value = ''} );
    },

    async onVerifySubmit() {
        this.$refs.VerifyFormRef.onVerify();
    }
  }
}
</script>

<style scoped>
.dink247-login-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dink247-verify-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dink247-login-content {
  --background: none; /* to override ion-content background this must be done or image has white bars */
}
</style>