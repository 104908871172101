import api from '@/services/api';

const friends = {
    namespaced: true,

    state: {
        data: [],
    },

    getters: {
        data(state) {
            return state.data;
        }
    },

    actions: {
        async get({ commit, rootState }) {
            const user = rootState.auth.user;
            if (!user) {
                return;
            }

            const r = await api.get(`/social/profiles/${user.id}/friends/`);
            commit('SET', r);  
          },
          
        async getProfileById({ commit, rootState }, id) {  
            const profile = rootState.profiles.data.find(obj => obj.id === id);

            if(profile){
                return profile;
            } else {
                const r = await api.get(`/social/profiles/${id}`);
                 
                commit('profiles/ADD_PROFILE', r.data, { root: true });
                
                return r.data;
            }
        },     

        async add({ commit, rootState }, params) {
            const user = rootState.auth.user;
            
            if (!user) {
                return;
            }

            const r = await api.post(`/social/profiles/${user.id}/requests/`, params);

            return r;
        }
    },

    mutations: {
        SET(state, data) {
            state.data = data;
        },
    },
}

export default friends;