import { createApp } from 'vue'
import '@/style.css'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import socket from '@/services/socket'
import setupSentry from '@/services/sentry';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/padding.css';
;

const app = createApp(App)

setupSentry(app);

app.use(socket);
app.use(router);
app.use(store);
app.use(IonicVue);

store.$socket = socket;

app.mount('#app');
