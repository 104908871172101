<template>
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col>
                <ion-input
                    :name="name"
                    label-placement="stacked" 
                    fill="solid"
                    :placeholder="placeholder"
                    v-model="modelValue"
                    @input="onInput"
                    @keyup.enter="handleKeyUp"
                    class="native-input ion-text-start dink-input">
                    <ion-icon src="/img/icons/ui/search-icon.svg" slot="start" class="search-icon"></ion-icon>
                </ion-input>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { IonCol, IonGrid, IonRow, IonInput, IonButton, IonIcon } from '@ionic/vue';

export default {
    name: 'SearchInput',

    components: {
        IonCol, IonGrid, IonRow, IonInput, IonButton, IonIcon
    },

    props: {
        name: {
            type: String,
            default: '',
            required: true
        },
        placeholder: {
            type: String,
            default: '',
            required: false
        },
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: '',
            required: false
        }
    },

    data() {
        return {
            modelValue: this.modelValue
        };
    },

    emits:["searchCallback", "update:modelValue"],
    methods: {
        onInput(e) {
            this.$emit("update:modelValue", e.target.value);
        },

        handleKeyUp(e){
            this.$emit('searchCallback', {'keyword': e.target.value});
        },
    },
    
    created() {
        this.modelValue = this.value;
    }
}
</script>

<style scoped>
label.small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}

ion-input {
    display: flex;
    padding: 16px;
    gap: 8px;
}

ion-button {
    width: 83px;
}

.search-icon {
    width: 24px;
    height: 24px;
}
</style>