<template>
    <ion-modal 
        ref="modal" 
        class="iphone-modal"
        :is-open="showIphoneModal" >
        <ion-header>
        <ion-toolbar>
            <span class="modal-title ion-margin-start">Install 24/7 Dink</span>
            <ion-buttons slot="end" class="ion-justify-content-end">
                <ion-button @click="dismiss" slot="end" class="text-right"><span slot="end" >Close</span></ion-button>
            </ion-buttons>
        </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding" scroll-y="false">
        <ion-grid>
            <ion-row>
            <ion-col class="ion-justify-content-center">
                <img src="/img/logos/247dink-logo-color-2x.png" width="70" height="40" alt="Install 24/7 Dink" class="modal-logo-image">
            </ion-col>
            </ion-row>
            <ion-row>
            <ion-col>
                Install 247 Dink to your device to easily access courts, matches, and other players.  
            </ion-col>
            </ion-row>
            <ion-row>
            <ion-col class="icon-col">
                1. Just tap <img src="/img/icons/iphone-share-icon-30x30.png" width="30" height="30" alt="Install 24/7 Dink" class="modal-title-image">
            </ion-col>
            </ion-row>
            <ion-row>
            <ion-col>
                2. Select 'Add to Home Screen'
            </ion-col>
            </ion-row>
        </ion-grid>
        </ion-content>
    </ion-modal>
</template>

<script>
    import { IonHeader, IonContent, IonCol, IonGrid, IonRow, IonButton, IonButtons, IonModal, IonToolbar  } from '@ionic/vue';
    import Header from '@/components/Header.vue';

    export default {
        name: 'iphoneDownloadModal',
        components: {
            IonHeader, IonContent, IonCol, IonGrid, IonRow, IonButton, IonButtons, IonModal, IonToolbar
        },

        props: {
            showIphoneModal: {
                type: Boolean,
                default: false
            }  
        },

        methods: {
            dismiss() {
                this.$refs.modal.$el.dismiss();
            }
        }
    }
</script>

<style scoped>
  .iphone-modal {
      height: 281px;
      position: absolute;
      width: 90%;
      border-radius: 26px;
      margin-left: auto;
      margin-right: auto;
      top: calc(100% - 298px);
      border: 1px solid #BABFC4;

      ion-content {
          background-color: #333;
          color: #eee;
      }
  }

  .iphone-modal ion-toolbar {
    --background: #0B4B80;
    --color: #fff;
  }

  .iphone-modal ion-buttons {
    width: 100px;
    --color: #fff;
  }

  .iphone-modal ion-button {
    --color: #fff;
    width: 65px;
  }

  .icon-col {
    line-height: 32px;
  }

  ion-title, .iphone-modal ion-title, .modal-title {
    font-size: 1.01rem;
    font-weight: 600;
  }

  .modal-logo-image {
    margin-bottom: 10px;
  }
</style>