import api from '@/services/api';
const messages = {
  namespaced: true,

  state: {
    data: [],
  },

  getters: {
    data(state) {
      return state.data;
    },
  },

  actions: {
    async get({ commit, rootState }) {
      const user = rootState.auth.user;
      if (!user) {
        return;
      }

      const r = await api.get(`/social/profiles/${user.id}/inbox/`);
      commit('SET', r);
    },

    async send({ commit, rootState }, params) {
      const user = rootState.auth.user;

      if (!user) {
        return;
      }

      const r = await api.post(`/social/profiles/${user.id}/outbox/`, params);

      return r;
    }
  },

  mutations: {
    SET(state, data) {
      state.data = data;
    },

    ADD(state, data) {
      state.data.push(data);
    },
  },
};

export default messages;