<template>
    <ion-page>
        <Header
            text="Billing Details"
            type="text"
            defaultHref="/subscribe"
            @backButtonCallback="backButtonCallbackHandler"
        ></Header>
        <ion-content class="ion-padding" scroll-y="false">
            <div class="ion-text-center ion-margin-bottom" v-if="subscriptionPackage">
              Price Summary: {{ subscriptionPackage.year_price_formatted }}
            </div>
            <div class="ion-text-center ion-margin-bottom">
              We need to have a credit card on file to charge for court time or facility services.
            </div>
            <BillingForm
                ref="form"
                :package="packageSelected"
                :coupon="coupon"
                @billingDetailsCallback="handleBillingDetailsCallback"
            ></BillingForm>
        </ion-content>
        <Footer
            buttonText="Submit"
            @click.prevent="next"
        ></Footer>
    </ion-page>
</template>
<script>
import { IonPage, IonContent } from '@ionic/vue';
import { ref, computed, onMounted  } from 'vue';
import { useStore  } from 'vuex';
import BillingForm from '@/components/subscription/BillingForm.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';
import FormVerification from '@/composables/verification.js'

const { formatDiplayPrice } = FormVerification();

export default {
  name: 'Biling Details',

  data() {
    return {
      subscriptionPackage: '',
    };
  },

  components: {
    IonPage, BillingForm, Header, Footer, IonContent
  },

  props: {
    packageSelected: {
      type: String,
      default: 'xdbG05v25BLQ2'
    },
    coupon: {
      type: String,
      default: null
    }
  },

  mounted() {
    const store = useStore();
    const subscriptionPackage = ref(null);

    const fetchData = async () => {
      const options = {
          id: this.packageSelected,
          coupon: this.coupon
        }      
        try {
          const fetchedPackage = await store.dispatch('payment/getPackages', options);
          
          // Shows discounted price if one exist it not show original price
          fetchedPackage.year_price_formatted = fetchedPackage.discounted
          ? formatDiplayPrice(fetchedPackage.discounted.year_price)
          : formatDiplayPrice(fetchedPackage.year_price);
          
          this.subscriptionPackage = fetchedPackage;
        } catch (error) {
            console.error('Error fetching package details:', error);   
        }
    };

    fetchData();

    return { subscriptionPackage } 
  },

  emits:["backButtonCallback"],   
  methods: {
    backButtonCallbackHandler(r) {
        this.$emit('backButtonCallback', r);
    },

    next() {
      try {
        this.$refs.form.submit();
      } catch (e) {
        console.error(e);
      }
    },
    
    handleBillingDetailsCallback(){
      this.$router.push('/invite/friend');
    }
  },
}
</script>