<template>
    <div class="ion-margin-top chip-nav">
        <ion-chip :class="{ active: active === 'matches' }" :onClick="handleMatchesClick">My Matches</ion-chip>
        <ion-chip :class="{ active: active === 'open' }" :onClick="handleOpenPlayClick">Open Play</ion-chip>
        <ion-chip :class="{ active: active === 'history' }" :onClick="handleHistoryClick">History</ion-chip>
    </div>
</template>

<script>
import { IonChip } from '@ionic/vue';

export default {
  name: 'MatchChip',

  components: {
    IonChip
  },

  props: {
      active: {
          type: String,
          default: null,
      }
  },
  methods: {
    async handleMatchesClick(){
        this.$router.push('/matches/schedule');
    },

    async handleOpenPlayClick(){
        this.$router.push('/matches/open');
    },

    async handleHistoryClick(){
        this.$router.push('/matches/history');
    }
  },
}
</script>