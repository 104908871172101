<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <FooterTabBar active="invite"></FooterTabBar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import { IonRouterOutlet, IonNav, IonPage, IonTabs } from '@ionic/vue';
import FooterTabBar from '@/components/ui/FooterTabBar.vue';

export default {
    name: 'Invite',
    components: {
        IonRouterOutlet, IonNav, IonPage, IonTabs, FooterTabBar
    }
}
</script>