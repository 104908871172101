<template>
    <form>
        <DefaultInput
            name="firstname"
            label="first name"
            placeholder="Enter your first name"
            v-model="this.formData.firstname.value"
            class="ion-margin-bottom"
            :value="firstname"
            :class="this.formData.firstname.classes"
        ></DefaultInput>
        <DefaultInput
            name="lastname"
            label="last name"
            placeholder="Enter your last name"
            v-model="this.formData.lastname.value"
            class="ion-margin-bottom"
            :value="lastname"
            :class="this.formData.lastname.classes"
        ></DefaultInput>
        <EmailInput
            v-model.lazy="this.formData.email.value"
            class="ion-margin-bottom"
            :class="this.formData.email.classes"
            :message="this.errorMessage"
            @update-error-message="handleErrorMessage"
            :value="this.emailAddress" 
            ref="emailInput" 
        ></EmailInput>
        <PhoneNumberInput
            v-model.lazy="this.formData.mobile.value"
            class="ion-margin-bottom"
            :class="this.formData.mobile.classes"
            :message="this.errorMessage"
            @update-error-message="handleErrorMessage" 
            :value="formatPhone"
            ref="phoneNumberInput" 
        ></PhoneNumberInput>
        <UsernameInput
            v-model.lazy="this.formData.username.value"
            class="ion-margin-bottom"
            :class="this.formData.username.classes"
            :message="this.errorMessage"
            :value="username"
            @update-error-message="handleErrorMessage" 
            ref="usernameInput"
        ></UsernameInput>
        <DateTimePicker
            name="dob"
            label="Date of Birth"
            v-model="this.formData.dob.value"
            class="ion-margin-bottom"
            :class="this.formData.dob.classes"
            :value="formatDOB"
            :wheel=true
            @update-error-message="handleErrorMessage" 
        ></DateTimePicker>
        <DefaultTextarea
            name="biography"
            label="BIO"
            v-model.lazy="this.formData.biography.value"
            class="ion-margin-bottom"
            :class="this.formData.biography.classes"
            :message="this.errorMessage"
            :value="this.biography"
            @update-error-message="handleErrorMessage"
        ></DefaultTextarea>
        <div
            v-if="errorMessage"
            class="error ion-margin-bottom ion-margin-horizontal"
        >{{ errorMessage }}</div>
    </form>
    <Footer
        id="footer"
        :buttonText=buttonText
        @click="submitUserDetails"
    ></Footer>    
</template>

<script>
import { computed, ref, watch } from 'vue';
import { IonPage, IonContent, IonTextarea, IonDatetime } from '@ionic/vue';
import { mapActions } from 'vuex';
import moment from 'moment'
import DefaultInput from '@/components/forms/DefaultInput.vue';
import DefaultTextarea from '@/components/forms/DefaultTextarea.vue'
import EmailInput from '@/components/forms/EmailInput.vue';
import PhoneNumberInput from '@/components/forms/PhoneNumberInput.vue';
import UsernameInput from '@/components/forms/UsernameInput.vue';
import DateTimePicker from '@/components/forms/DateTimePicker.vue'
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';
import FormVerification from '@/composables/verification.js'
import * as validator from 'email-validator';

const { displayErrorMessage, validateForm, isValidPhone, isOfAge, cleanPhone } = FormVerification();
const usernameInput = ref(null);
const phoneNumberInput = ref(null);
const emailInput = ref(null);

export default {
  name: 'UserProfileForm',

  components: {
        IonPage, IonContent, IonTextarea, IonDatetime, Header, Footer, DefaultInput, DefaultTextarea, EmailInput, PhoneNumberInput, 
        UsernameInput, DateTimePicker, FormVerification
  },
  
  props: {
    emailAddress: String,
    mobile: String,
    firstname: String,
    lastname: String,
    username: String,
    dob: String,
    biography: String,
    buttonText: String,
  },

  mounted() {
    this.formData.mobile.value = this.formatPhone;
    this.formData.mobile.oldValue = this.formatPhone;

    this.formData.email.value = this.formatEmail;
    this.formData.email.oldValue = this.formatEmail;

    this.formData.firstname.value = this.firstname;
    this.formData.firstname.oldValue = this.firstname;

    this.formData.lastname.value = this.lastname;
    this.formData.lastname.oldValue = this.lastname;

    this.formData.username.value = this.username;
    this.formData.username.oldValue = this.username;

    this.formData.dob.value = this.formatDOB;
    this.formData.dob.oldValue = this.formatDOB;

    this.formData.biography.value = this.biography;
    this.formData.biography.oldValue = this.biography;
  },

  computed: {
    formatPhone() {
      var clean = ( this.mobile || '');

      // Remove leading + if one exist
      clean = clean.replace(/^[\+]/, '');  
      // Remove leading 1 if one exist
      clean = clean.replaceAll(/^1/g, '');
      // Apply phone ###-###-### formatting
      clean = clean.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

      return clean;
    },

    formatEmail() {
      var clean = ( this.emailAddress || '');

      return clean;
    },

    formatDOB() {
      if(this.dob){
        return this.dob;
      } else {
        return moment().format('YYYY-MM-DD');
      }
    }
  },

  data() {
    return {
      formData: {
        firstname: {
          value: '',
          oldValue: '',
          rules: [
            (v) => !!v || 'First name is a required field',
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        lastname: {
          value: '',
          oldValue: '',
          rules: [
            (v) => !!v || 'Last name is a required field',
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        mobile: {
          value: this.formatPhone,
          oldValue: this.formatPhone,
          rules: [
            (v) => !!v || 'Phone number is a required field',
            (v) => isValidPhone(v) || 'A valid phone number must be used'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        email: {
          value: this.formatEmail,
          oldValue: this.formatEmail,
          rules: [
            (v) => !!v || 'Email is a required field',
            (v) => validator.validate(v) || 'Email must be valid'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        username: {
          value: '',
          oldValue: '',
          rules: [
            (v) => !!v || 'Nickname is required',
            //(v) => this.validUsername(v) || 'Nickname is already taken or is not allowed' issue using promises
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        dob: {
          value: '',
          oldValue: '',
          rules: [
            (v) => !!v || 'Date of birth is a required field',
            (v) => isOfAge(v) || 'Must be over 18'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        biography: {
          value: '',
          oldValue: '',
          rules: [],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
      },
      errorMessage: null
    }
  },

  emits:['formCallback','backButtonCallback'],
  methods: {
    backButtonCallbackHandler(r) {
        this.$emit('backButtonCallback', r);
    },

    async submitUserDetails() {
      if(!validateForm(this.formData)){
        this.errorMessage = displayErrorMessage(this.formData);
        return false;
      }

      //Clean phone for api and comparison
      this.formData.mobile.value = cleanPhone(this.formData.mobile.value);
      this.formData.mobile.oldValue = cleanPhone(this.formData.mobile.oldValue);

      // Unique email check if value has changed
      if(this.formData.email.value != this.formData.email.oldValue){
        if(!await this.$refs.emailInput.validEmail(this.formData.email.value)){
          return false;
        }
      }
      
      // Unique phone number check if value has changed
      if(this.formData.mobile.value != this.formData.mobile.oldValue){
        if(!await this.$refs.phoneNumberInput.validPhone(this.formData.mobile.value)){
          return false;
        }
      }

      // Unique username check if value has changed
      if(this.formData.mobile.value != this.formData.mobile.oldValue){
        if(!await this.$refs.usernameInput.validUsername(this.formData.username.value)){
          return false;
        }
      }

      this.$emit('formCallback', this.formData);
    },
    
    handleErrorMessage(message){
      this.errorMessage = '';
      this.errorMessage = message;
    },
  }
}
</script>