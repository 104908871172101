import { SENTRY_DSN, VERSION } from '@/config'
import * as Sentry from "@sentry/vue";

function setupSentry(app) {
  if (!SENTRY_DSN) {
    return;
  }

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/247dink\.com\/api/,
      /^https:\/\/www\.247dink\.com\/api/,
      /^https:\/\/247dinkbeta\.com\/api/,
      /^https:\/\/www\.247dinkbeta\.com\/api/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: VERSION,
  });
}

export default setupSentry;
