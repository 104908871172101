<template>
    <DefaultInput
        name="mobile"
        label="mobile"
        placeholder="Enter your mobile number"
        v-model="value"
        class="ion-margin-bottom"
        :class="this.formData.mobile.classes"
        :value="formatPhone"
        @ionBlur="handlePhoneChange"
        @keyup="handleKeyUp"
    ></DefaultInput>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { mapActions } from 'vuex';
import DefaultInput from '@/components/forms/DefaultInput.vue';
import FormVerification from '@/composables/verification.js'

const { isValidPhone, cleanPhone } = FormVerification();

export default {
  name: 'PhoneNumberInput',
  expose: ['validPhone'],
  components: {
        DefaultInput
  },

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    unique: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    formatPhone() {
      var clean = ( this.modelValue || '');

      // Remove leading + if one exist
      clean = clean.replace(/^[\+]/, '');  
      // Remove leading 1 if one exist
      clean = clean.replaceAll(/^1/g, '');
      // Apply phone ###-###-### formatting
      clean = clean.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

      return clean;
    },
    
    formatEmail() {
      var clean = ( this.emailAddress || '');

      return clean;
    }
  },

  data() {
    return {
      value: this.modelValue,
      rules: [
        (v) => !!v || 'Required',
      ],
      classes: {
        'error': false
      },
      formData: {
        mobile: {
          value: this.formatPhone,
          rules: [
            (v) => !!v || 'Phone number is a required field',
            (v) => isValidPhone(v) || 'A valid phone number must be used'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        }
      },
      typingCheck:false,
      errorMessage: ''
    }
  },

  watch: {
    value(value) {
      this.$emit('update:modelValue', value);
    },
  },

  methods: {
    ...mapActions({checkPhone:'auth/checkUnique'}),
    handleKeyUp() {
      // Typing check added if onblur check fails
      if(this.typingCheck){
        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
          this.validPhone(this.value);
        }, 500);        
      }
    },

    async validPhone(mobileToCheck = false){
      var r = null;
      var errorMessage = "";
      this.formData.mobile.classes.error = false;
      this.errorMessage = '';

      if(!mobileToCheck || mobileToCheck.length <= 0){
        this.formData.mobile.classes.error = true;
        return false;
      }

      if(!isValidPhone(mobileToCheck)){
        this.formData.mobile.classes.error = true;
        this.errorMessage = "Phone number is not valid.";
        this.$emit('update-error-message', this.errorMessage);
        return false;
      }

      r = await this.checkPhone({ 
        mobile: mobileToCheck 
      }).catch((r) => {
        return r.response;
      });

      if(r.status && r.status != 404) {
        if(r.status == 200) {
          // phone number found
          this.errorMessage = "Phone number is already being used.";
        } else if (r.status == 400) {
          // banned mobile list
          this.errorMessage = "This number is not allowed.";
        } else {
          this.errorMessage = "There was an issue please try again.";
        }

        this.$emit('update-error-message', this.errorMessage);
        this.formData.mobile.classes.error = true;
        return false;
      }  else {

        // phone not found and is valid ( 404 )
        this.formData.mobile.classes.error = false;
        this.$emit('update-error-message', '');
        return true;
      }
    },

    async handlePhoneChange(event){
      // If blur check fails add typing check
      if(!await this.validPhone(event.target.value)) {
        this.typingCheck = true;
      }
    }
  }
}
</script>