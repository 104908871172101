import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-3-socket.io';
import store from '@/store';
import { API_BASE_URL } from '@/config';

const apiHostname = new URL(API_BASE_URL).hostname;

export default new VueSocketIO({
  debug: true,
  connection: SocketIO(`wss://${apiHostname}`, { path: '/socket.io', autoConnect: false, transports: ['websocket'] }),
  vuex: {
    store,
    actionPrefix: 'socket_'
  }
});
