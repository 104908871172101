import api from '@/services/api';

const app = {
    namespaced: true,

    state() {
        return {
            requests: 0,
            waffle: null,
        };
    },

    getters: {
        busy(state) {
            return Boolean(state.requests > 0);
        },
    },

    actions: {
        async getWaffle({state, commit}) {
            if (state.waffle !== null) {
                return;
            }
            const r = await api.get('core/waffle/');
            commit('SET_WAFFLE', r);
        },

        async switch({ state, dispatch }, name) {
            await dispatch('getWaffle');
            if (!state.waffle.switches) {
                return false;
            }
            return Boolean(state.waffle.switches[name]?.is_active);
        },

        async flag({ state, dispatch }, name) {
            await dispatch('app/getWaffle');
            if (!state.waffle.flags) {
                return false;
            }
            return Boolean(state.waffle.flags[name]?.is_active);
        },

        async sample({ state, dispatch }, name) {
            await dispatch('app/getWaffle');
            if (!state.waffle.samples) {
                return false;
            }
            return state.waffle.samples[name]?.is_active;
        },
    },

    mutations: {
        INC_REQUESTS(state) {
            state.requests += 1;
        },

        DEC_REQUESTS(state) {
            state.requests = Math.max(0, state.requests - 1);
        },

        SET_WAFFLE(state, value) {
            state.waffle = value;
        },
    },
}

export default app;