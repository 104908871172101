<template>
    <Header
        text="Package Type"
        type="text"
        :showLogout="true"
    ></Header>
    <ion-content class="ion-padding">
        <PlanSelectionRadioInput
            v-model:selectedValue="formData.plan.value"
            :options = "options"   
        ></PlanSelectionRadioInput>
        <DefaultInput
            name="couponCode"
            label="coupon code"
            placeholder="Enter coupon code"
            type="button"
            class="ion-padding-top ion-margin-bottom coupon-input"
            v-model="this.formData.coupon.value"
            :onClick="handleApplyCouponCallback"
        ></DefaultInput>
        <div
            v-if="errorMessage"
            class="error ion-margin-bottom ion-margin-horizontal"
          >{{ errorMessage }}</div>
    </ion-content>
    <Footer
        buttonText="Next"
        @click="submitPlanSelection"
    ></Footer>
</template>

<script>
import { IonNav, IonPage, IonContent, IonButton, IonButtons } from '@ionic/vue';
import DefaultInput from '@/components/forms/DefaultInput.vue';
import PlanSelectionRadioInput from '@/components/subscription/PlanSelectionRadioInput.vue';
import FormVerification from '@/composables/verification.js'
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';

const { displayErrorMessage, validateField, clearErrors } = FormVerification();

export default {
    name: 'Package Selection',
    components: {
        IonNav, IonPage, Header, Footer, IonContent, IonButton, IonButtons, DefaultInput, PlanSelectionRadioInput, FormVerification
    },
    
    emits:["packageSelectionCallback", "update:model-value"],
    methods: {
        submitPlanSelection() {
          clearErrors(this.formData);
          this.errorMessage = '';
          
          this.$emit('packageSelectionCallback', { formData: this.formData });
        },
        handleApplyCouponCallback(){
          clearErrors(this.formData);
          this.errorMessage = '';

          if(!validateField(this.formData.coupon)){
            this.errorMessage = displayErrorMessage(this.formData);
            return false;
          }

          this.options = {coupon: this.formData.coupon.value};  // test coupon 69FOURTWENTY
        }
    },
    
    data() {
      return {
        options: { },
        formData: {
          plan: {
            value: null,
            rules: [
              
            ],
            error: {
              messages: []
            },
            classes: {
              'error': false
            }
          },
          coupon: {
            value: null,
            rules: [
              (v) => !!v || 'Coupon is required',
            ],
            error: {
              messages: []
            },
            classes: {
              'error': false
            }
          }
        },
        errorMessage: ''
      }
    }
}
</script>