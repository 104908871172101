<template>
    <ion-header collapse="fade" class="ion-no-border">
        <ion-toolbar class="ion-text-center">
            <!--TODO refactor this ion-toolbar section-->
            <ion-buttons slot="start" v-if="defaultHref">
                <ion-back-button 
                text="" 
                :icon="arrowBack" 
                :defaultHref="defaultHref" 
                :onClick="handleBackButtonClick"
                class="ion-float-left"></ion-back-button>
            </ion-buttons>
            <ion-title v-if="type === 'logo'">
                <img src="/img/logos/247dink-logo-color-2x.png"
                    alt="24/7 Dink Logo"
                    width="84"
                    height="48" class="ion-justify-content-center ion-margin-top"> 
            </ion-title>
            <ion-title v-else-if="type === 'text'">
                <h2><strong>{{text}}</strong></h2>
                <span class="message-subtitle" v-if="subTitle"><h4>{{ subTitle }}</h4></span>
            </ion-title>
            <ion-buttons slot="end" class="ion-margin-end" v-else-if="type === 'edit'">
                <ion-button
                    type="submit" 
                    shape="circle" 
                    expand="block" 
                    class="btn-message-edit"
                    :onClick="handleEditClick"
                ><ion-icon src="/img/icons/ui/edit-icon.svg" class="item-icon" slot="icon-only"></ion-icon></ion-button>   
            </ion-buttons>
            <ion-buttons slot="end" class="ion-margin-end" v-else-if="type === 'msg'">
                <ion-button
                    type="submit" 
                    shape="circle" 
                    expand="block" 
                    class="btn-message-send"
                    :onClick="handleMessageClick"
                ><ion-icon src="/img/icons/ui/msg-chat-circle.svg" class="feed-icon" slot="icon-only"></ion-icon></ion-button> 
            </ion-buttons>
            <ion-buttons slot="end" class="ion-margin-end" v-if="isAuthenticated && showLogout">
                <ion-button
                    class="dink-logout"
                    fill="clear"
                    :onClick="handleLogoutClick"
                > logout </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>

<script>
import { IonBackButton, IonNav, IonHeader, IonToolbar, IonTitle, IonButton, IonButtons, IonIcon} from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Header',
    components: {
        IonBackButton, IonNav, IonHeader, IonToolbar, IonTitle, IonButton, IonButtons, IonIcon
    },

    data() {
        return {
            arrowBack: null
        }
    },
    
    props: {
        logo: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'logo',
        },
        text: {
            type: String,
            default: '',
        },
        subTitle: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
        defaultHref: {
            type: String,
            default: null,
        },
        showLogout: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
        ...mapGetters({
            'isAuthenticated': 'auth/isAuthenticated',
        }),
    },

    emits:["backButtonCallback"],
    methods: {
        ...mapActions({logout:'auth/logout'}), 

        handleBackButtonClick() {
            this.$emit('backButtonCallback', {'page': this.defaultHref});
        },

        handleEditClick() {
            this.$router.push('/profile/edit');
        },

        handleMessageClick() {
            this.$router.push('/community/messages');
        },
        
        async handleLogoutClick(){
            await this.logout().catch((err) => {});
            this.$router.push('/login');
        }
    },
}
</script>
<style scoped>
    ion-back-button {
        padding-left:16px;
        margin-top: 10px;
        position: fixed;
    }

    ion-button[shape=circle] {
        --border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    .btn-message-send, .btn-message-edit {
        margin-top: 22px;
    }

    ion-title {
        background: #fff;
    }

    ion-toolbar{
        --min-height:60px;
    }

    .dink-logout {
        color: #000;
        --background: #fff;
        --ion-toolbar-color: black;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        width: auto;
        margin-top: 5px;
    }

    h2 {
        margin-bottom: 0px;
    }

    .message-title {
        width: auto;
        min-width: 60%;
        max-width: 91%;
    }

    .message-header-title {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .message-subtitle {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #777777;
    }

    .message-subtitle h4 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 14px;
    }

    .message-avatar img {
        border-radius: 50px;
    }

    .message-group-avatar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3px;
    }
</style>
