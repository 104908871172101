<template>
    <ion-grid>
        <ion-row class="ion-margin-bottom">
            <ion-col>
                <img :src="getAvatarSource(id)"
                    alt="24/7 Dink Avatar"
                    width="80"
                    height="80" class="ion-justify-content-center avatar-circle">
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <span class="profile-name">@{{ name }}</span>
                <div class="feed-badges">
                    <!-- TODO add badges -->
                </div>
            </ion-col>
        </ion-row>
        <ion-row class="ion-margin-bottom">
            <ion-col class="profile-bio">
                {{ bio }}
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-list class="profile-rating">
                    <ion-item>
                        <ion-label>Dink Rank</ion-label>
                        <ion-label class="ion-text-right">{{ rank }}</ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label>Games Played</ion-label>
                        <ion-label class="ion-text-right">{{ games }}</ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label>Home Court</ion-label>
                        <ion-label class="ion-text-right">{{ court }}</ion-label>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { IonGrid, IonCol, IonRow, IonList, IonItem, IonIcon, IonLabel } from '@ionic/vue';

export default {
  name: 'Profile',

  components: {
    IonGrid, IonCol, IonRow, IonList, IonItem, IonIcon, IonLabel
  },
  
  props: {
      id: {
          type: String,
          default: '',
      },
      name: {
          type: String,
          default: '',
      },
      bio: {
          type: String,
          default: '',
      },
      rank: {
          type: String,
          default: '',
      },
      games: {
          type: String,
          default: '0',
      },
      court: {
          type: String,
          default: '',
      }
  },

  methods: {
    getAvatarSource(id){
      return `/api/1.0/social/profiles/${id}/avatar/`;
    }
  }
}
</script>

<style scoped>
    .profile-name {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-left: 5px;
    }

    .profile-rating {
        display: flex;
        flex-direction: column;
        padding: 7px 13px;
        background: #F7F7F7;
        --ion-item-background:F7F7F;
        border-radius: 16px;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        --inner-padding-end:0px;
        --inner-padding-start:0px;
    }

    ion-item {
        --padding-start: 0px;
    }

    ion-item:last-child {
        --inner-border-width: 0;
    }

    .feed-badges {
        display: flex;
        gap: 3px;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
    }
</style>