<template>
    <div class="ion-margin-horizontal match-item green">
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-grid>
                        <ion-row>
                            <ion-col>
                                <ion-label>DATE</ion-label>
                            </ion-col>
                            <ion-col>
                                <ion-label>{{ formattedDate }}</ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-label>TIME</ion-label>
                            </ion-col>
                            <ion-col>
                                <ion-label>{{ formattedTime }}</ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-label>MATCH TYPE</ion-label>
                            </ion-col>
                            <ion-col>
                                <ion-label>{{ type }}</ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-label>PLAYER STATUS</ion-label>
                            </ion-col>
                            <ion-col>
                                <ion-label>{{ player }}</ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
    <div>
        <ion-img
            :src=qr
            alt="Access your 247 Dink Match"
            width="180"
            hieght="180"
            class="match-qr"
        ></ion-img>
    </div>
</template>

<script>
import { IonGrid, IonCol, IonRow, IonLabel, IonImg } from '@ionic/vue';

export default {
  name: 'MatchDetail',
  components: {
    IonGrid, IonCol, IonRow, IonLabel, IonImg
  },

  data() {
    return {
      match: null,
    };
  },

  computed: {
    // TODO: add api connection to pull data
    formattedDate(){
        return '12/14/24';
    },

    formattedTime(){
        return '10:00 AM';
    },

    type(){
        return 'Closed Match';
    },

    player() {
        return 'Bill Smith';
    },

    qr() {
        return '/img/qrs/qr-code-placeholder.png';
    }
  }
}
</script>

<style scoped>
    .match-item {
        height: auto;
        --background: #F7F7F7;
        border-radius: 16px;
        margin-bottom: 13px;
    }

    .match-item ion-grid{
        padding-top: 3px;
    }

    .match-item ion-col{
        padding-left: 0px;
        padding-right: 0px;
    }

    .item-native {
        --padding-left: 0px !important;
    }

    .match-item.green {
        border-left: 8px solid #2ECC71;
    }

    .match-item.red {
        border-left: 8px solid #FF3B30;
    }

    .match-item.yellow {
        border-left: 8px solid #F1CE1E;
    }

    .match-item ion-label {
        margin-left: 8px;
    }

    .match-item-time {
        min-width: 168px;
    }

    .match-qr {
        width: 180px;
        height: 180px;
        margin-left: auto;
        margin-right: auto;
    }

</style>