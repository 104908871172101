<template></template>

<script>
  import { mapGetters } from 'vuex';
  import { loadingController } from '@ionic/vue';

  export default {
    components: { loadingController },

    computed: {
      ...mapGetters({
        busy: 'app/busy',
      }),
    },

    data() {
        return { loadingRef: null }
    },

    watch: {
      async busy() {
        if (this.busy) {
            const loading = await loadingController.create({
                message: 'Please wait...',
            });          
            loading.present();
            this.loadingRef = loading; // Assign reference to loading element
            
            // Check to make sure that the loadingController finished before busy is false
            if(!this.busy){
              this.loadingRef.dismiss();
            }         
        } else {
            if (!this.loadingRef) {
              console.warn('Loading element not found (ref might be null)');
              return;
            }
            this.loadingRef.dismiss();
            this.loadingRef = null; // Clear reference after dismissal
        }
      },
    },
  };
</script>