const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || location.origin;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const VERSION = import.meta.env.VITE_VERSION;
const API_VERSION = '1.0';
const STRIPE_PUBLISHABLE_KEY = 'pk_test_51Oa54ZHNSBz6hHlEggPXZWbiXqOWE9MmW7Fzpp9nX1QmrclNxzQjJPmSXJg0IiAMLTLchObb0ggmzA0QeXdl1vDW00rGYudJJR';

export {
    API_BASE_URL,
    API_VERSION,
    VERSION,
    SENTRY_DSN,
    STRIPE_PUBLISHABLE_KEY,
};
