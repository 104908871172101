<template>
    <ion-item 
        lines="none" 
        class="message-item" 
        :class="{ unread: isUnread, online: isOnline }"
        >
        <ion-grid>
            <ion-row>
                <ion-col size="3" class="message-avatar ion-align-items-center ion-justify-content-center">
                    <template v-if="typeof avatarImg === 'string'">
                        <img :src="avatarImg"
                            alt="24/7 Dink Avatar"
                            width="50"
                            height="50" class="ion-justify-content-center"> 
                    </template>
                    <template v-else-if="Array.isArray(avatarImg)">
                        <div class="message-group-avatar">
                            <img v-for="imgItem in avatarImg" :key="imgItem"
                                :src="imgItem"
                                alt="24/7 Dink Avatar"
                                width="32"
                                height="32" class="ion-justify-content-center"
                            ></img>
                        </div>
                    </template>
                </ion-col>
                <ion-col size="7" class="message-item-border">
                    <ion-grid class="message-item-line">
                        <ion-row>
                            <ion-col>
                                {{sender}}
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <div class="message-text ion-text-nowrap">{{message}}</div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
                <ion-col size="2" class="message-time ion-align-items-center ion-justify-content-end message-item-border">
                    {{messageTime}}
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>     
</template>

<script>
import { IonGrid, IonCol, IonRow, IonLabel, IonIcon, IonList, IonItem } from '@ionic/vue';

export default {
  name: 'Community Chat',

  components: {
    IonGrid, IonCol, IonRow, IonLabel, IonIcon, IonList, IonItem
  },

  props: {
    id: String,
    message: String,
    messageTime: String,
    sender: String,
    avatarImg: {
        default: '',
    },
    avatarImgGroup: Array,
    isOnline: {
        type: Boolean,
        default: false,
    },
    isUnread: {
        type: Boolean,
        default: false,
    }
  }
}
</script>

<style scoped>
    .message-list {
        margin-left: 0px;
        margin-inline-start: 0px;
        -webkit-margin-end: 0px;
    }

    .message-item {
        height: 100px;
        margin-bottom: 13px;
    }

    .message-item.unread {
        font-weight: 500;
    }

    .message-item ion-grid{
        padding-top: 3px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .message-item ion-col{
        padding-left: 0px;
        padding-right: 0px;
    }

    .item-native {
        --padding-left: 0px !important;
    }

    .message-item ion-label {
        margin-left: 8px;
    }

    .message-item-border {
        border-bottom: 1px solid #E4E5E6;
    }

    .message-item.online .message-avatar::before {
        content: "";
        width: 12px; 
        height: 12px; 
        background-color: #30FF3A;
        border-radius: 50%; 
        position: absolute;
        bottom: 10px;
        right: 10px;
        transform: translate(-50%, -50%); 
    }

    .message-avatar img {
        border-radius: 50px;
    }

    .message-group-avatar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3px;
    }

    .message-item-line {
        max-width: 100%;
    }

    .message-text {
        max-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 226px;
    }

    .message-time {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }

    .message-item-time {
        min-width: 168px;
    }

    .item-icon {
        font-size: 24px;
    }

    ion-button {
        --height: 32px;
    }

    .btn-check-in {
        width: auto;
        height: 50px;
    }

    ion-button[shape=circle] {
        --border-radius: 50%;
        width: 50px;
        height: 50px;
    }
</style>