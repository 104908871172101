<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <AvatarToolbar></AvatarToolbar>
            <CommunityChip
                active="support"
            ></CommunityChip>
        </ion-header>            
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row>
                    <ion-col>
                            <ion-list lines="none">
                                <ion-item>
                                    <ion-icon src="/img/icons/ui/phone-icon.svg" class="item-icon"></ion-icon>
                                    <ion-label class="ion-margin-start">
                                        <div>Phone Number</div>
                                        <div>(888) 844-DINK</div>
                                    </ion-label>
                                    <ion-icon src="/img/icons/ui/item-arrow-icon.svg" class="item-arrow" slot="end"></ion-icon>
                                </ion-item>
                                <ion-item>
                                    <ion-icon src="/img/icons/ui/mail-icon.svg" class="item-icon"></ion-icon>
                                    <ion-label class="ion-margin-start">
                                        <div>Email</div>
                                        <div>Support@247DINK.com</div>
                                    </ion-label>
                                    <ion-icon src="/img/icons/ui/item-arrow-icon.svg" class="item-arrow" slot="end"></ion-icon>
                                </ion-item>
                            </ion-list>        
                    </ion-col>
                </ion-row>                                                        
            </ion-grid>
        </ion-content>
        <ion-footer class="ion-no-border">
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-justify-content-center">

                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-footer>
    </ion-page>    
</template>

<script>
import { IonPage, IonNav, IonContent, IonIcon, IonGrid, IonCol, IonRow, IonList, IonItem, IonLabel, IonHeader, 
    IonFooter, IonChip } from '@ionic/vue';
import Header from '@/components/Header.vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import CommunityChip from '@/components/ui/nav/CommunityChip.vue';

export default {
    name: 'Support',
    components: {
        IonPage, IonNav, Header, AvatarToolbar, IonContent, IonIcon, IonGrid, IonCol, IonRow, 
        IonList, IonItem, IonLabel, IonHeader, IonFooter, IonChip, CommunityChip
    },

    methods: {
        backButtonCallbackHandler(r) {
            this.$router.back();
        },
    }
}
</script>

<style scoped>
ion-list {
    border-radius: 16px;
    --background: #d88686;
    width: 97%;
}

ion-item {
    --background: #F7F7F7;
}

ion-item:last-child ion-label {
    --border-width: 0;
    --border-color: transparent;
    border-bottom: 0px;
}

ion-label {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 12px;
}

.item-icon {
    background: #044784;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
    gap: 4px;
    width: 22px;
    height: 22px;
    margin-bottom: 12px;
}

.item-arrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 6px;
    height: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
}

ion-footer {
    position: fixed; 
    bottom: 0;
}
</style>